import { Grid, TextField, Button } from '@mui/material';
import axios from "axios";
import { Typography,Tooltip } from "@mui/material"
import { makeStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { useState, useEffect } from "react";
import Admin from "./Admin";
import { DesktopDatePicker } from "@mui/lab";
import Autocomplete from "@mui/material/Autocomplete";
import { API_HOST } from "../utils/constants";


const useStyles = makeStyles({

  addColor: {
    backgroundColor: "#0033a0",
    color: "white"
  },
  
})

const SupplierDesign = () => {
  const classes = useStyles()
  const [items,setItems] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [supplierData, setSupplierData] = useState([]);
  const [metaDataList, setMetaDataList] = useState([]);
  const [updateMode, setUpdateMode] = useState(false);
  let initialValues = {
    metadataRelationId:null,
    supplierCode: {supplierCode: "DEFAULT", supplierName: "Default", stateCode: "36"},
    effectiveDate:new Date().toISOString(),
    designCode:'',
    defaultBoxSize:'',
    sizeId:null,
    wholesalePrice:'',
    retailPrice:''
}
  let [data, setData] = useState(initialValues);

  let [status, setStatus] = useState();

    function textChange(e ,type='') {
        type ? 
        setData({
          ...data,
          [type]: e
      }) : setData({
            ...data,
            [e.target.name]: e.target.name === "wholesalePrice" || e.target.name === "retailPrice" || e.target.name === "defaultBoxSize"
            ? parseInt(e.target.value) : e.target.value
        })
    }
  //   const changeCase=(event)=>{
  //     event.preventDefault();
  //     setData(event.target.value.toUpperCase());
  // }

  const onChangeMultiSelect = (val, name) => {
    setData({
      ...data,
      [name]: val
  })
    // const ids = val.map((v) => v.id);
    // setstate({ ...state, sizeIds: ids.join() });
  };

    useEffect(() => {
        async function getdata() {
            try {
                const item = await axios.get(`${API_HOST}/SupplierDesignRelation`)
                setItems(item.data);
            } catch (error) {
                console.log("Something is Wrong");
            }
        }
        axios.get(`${API_HOST}/Constants`)
        .then((response) => {
          setSizes(response.data.sizeData);
          setSupplierData(response.data.supplierData);
          // setLoading(false);
        })
        .catch((err) => {
          // setError(err.message);
          // setLoading(false);
        });
        axios.get(`${API_HOST}/MetadataRelation`)
        .then((response) => {
          setMetaDataList(response.data);
          // setLoading(false);
        })
        .catch((err) => {
          // setError(err.message);
          // setLoading(false);
        });
        getdata();
    }, [])

    async function onFormSubmit(e) {
        e.preventDefault();
        const isEmpty = Object.values(data).every(x => x === null || x === '');
        if(isEmpty){
            alert("all fields are mandatory");
        }
        else{ 
          data["metadataRelationId"] = data.metadataRelationId.id;
          data["supplierCode"] = data.supplierCode.supplierCode;
          data["sizeId"] = data.sizeId.id;
          data["effectiveDate"] = new Date(data.effectiveDate).toISOString();
        try {
          if(updateMode){
            data["id"] = updateMode;
            await axios.patch(`${API_HOST}/SupplierDesignRelation`, {
                ...data,
            });
            alert("Data Successfully Updated.");
            setStatus(true);
          } else {
            await axios.post(`${API_HOST}/SupplierDesignRelation`, {
                ...data,
            });
            alert("Data Successfully Posted.");
            setStatus(true);
          }
        } catch (error) {
            console.log("Something is Wrong");
        }
    }
    }
    if (status) {
        return <SupplierDesign />
    }

    const handleEdit = (editId) => {
      setUpdateMode(editId);
      const rowData = items?.filter(it => it.id === editId);
      const sizeObj = sizes?.filter(sz => sz.id === rowData[0].size.Key);
      const supplierObj = supplierData?.filter(sf => sf.supplierCode === rowData[0].supplierInfo.Key);
      const metadataObj = metaDataList?.filter(md => md.id === rowData[0].metadataRelationId);
      setData({
        metadataRelationId: metadataObj[0],
        supplierCode:supplierObj[0],
        effectiveDate:rowData[0].effectiveDate,
        designCode:rowData[0].designCode,
        defaultBoxSize:rowData[0].defaultBoxSize,
        sizeId:sizeObj[0],
        wholesalePrice:rowData[0].wholesalePrice,
        retailPrice:rowData[0].retailPrice
      })
      window.scrollTo(0, 0)
    }

    const handleDelete = async (id) => {
        await axios.delete(`${API_HOST}/SupplierDesignRelation?Id=${id}`);
        var newData = items.filter((item) => {
            // console.log(item);
            return item.id !== id;
        });
        alert("Data Successfully Deleted.");
        setItems(newData);
    }

  return (
    <>
    <Admin />
      <Grid container >
        <Grid item >
          <Box textAlign="center" p={2} className={classes.addColor} mb={2}>
            <Typography variant="h4">Add Supplier Design Data</Typography>
          </Box>
          <form noValidate>
            <Grid ml={1} container spacing={2}>
            <Grid item >
            <Autocomplete
                // multiple
                style={{width:"250px"}}
                id="tags-standard"
                name="metadataRelationId"
                options={metaDataList}
                value={data?.metadataRelationId}
                onChange={(e, val) => onChangeMultiSelect(val, "metadataRelationId")}
                getOptionLabel={(option) => option?.metaDataName}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Metadata" />
                )}
              />
                {/* <TextField autoComplete="metadataRelationId" name="metadataRelationId"  required fullWidth id="metaDate" color="error" label="metadataRelationId" onChange={e => textChange(e)} /> */}
              </Grid>
              <Grid item >
              <Autocomplete
                // multiple
                style={{width:"250px"}}
                id="tags-standard"
                name="supplierCode"
                options={supplierData}
                value={data?.supplierCode}
                onChange={(e, val) => onChangeMultiSelect(val, "supplierCode")}
                getOptionLabel={(option) => option?.supplierName}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Supplier Code" />
                )}
              />
                {/* <TextField autoComplete="supplierInfo" name="supplierInfo"  required fullWidth id="supplierInfo" color="error" label="Supplier Code" onChange={e => textChange(e)} /> */}
              </Grid>
              <Grid item >
              <DesktopDatePicker
                required
                name="effectiveDate"
                label="Effective Date"
                inputFormat="dd/MM/yyyy"
                onChange={(e) => textChange(e, "effectiveDate")}
                value={data.effectiveDate}
                // onChange={(date) =>
                //   setInventoryData({
                //     ...inventoryData,
                //     [name]: new Date(date).toISOString(),
                //   })
                // }
                renderInput={(params) => <TextField fullWidth {...params} />}
              />  
                {/* <TextField autoComplete="effectiveDate" name="EffectiveDate" onMouseEnter={changeCase}   required fullWidth id="hsn" color="error" label="Hsn" onChange={e => textChange(e)} /> */}
              </Grid>
              <Grid item >
                <TextField autoComplete="designCode" value={data?.designCode} name="designCode"  required fullWidth id="designCode" color="error" label="Design Name" onChange={e => textChange(e)} />
              </Grid>
              <Grid item >
                <TextField type="number" value={data?.defaultBoxSize} autoComplete="defaultBoxSize" name="defaultBoxSize"  required fullWidth id="defaultBoxSize" color="error" label="Default Box Size" onChange={e => textChange(e)} />
              </Grid>
              <Grid item >
              <Autocomplete
                // multiple
                style={{width:"250px"}}
                id="tags-standard"
                name="sizeId"
                options={sizes}
                value={data?.sizeId}
                onChange={(e, val) => onChangeMultiSelect(val, "sizeId")}
                getOptionLabel={(option) => option?.sizeValue}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Sizes" />
                )}
              />
                {/* <TextField autoComplete="size" name="size"  required fullWidth id="size" color="error" label="Size Range" onChange={e => textChange(e)} /> */}
              </Grid> 
              <Grid item >
                <TextField type="number" value={data.wholesalePrice} autoComplete="wholesalePrice" name="wholesalePrice"  required fullWidth id="wholesalePrice" color="error" label="WholeSale Rate" onChange={e => textChange(e)} />
              </Grid> 
              <Grid item >
                <TextField type="number" value={data.retailPrice} autoComplete="retailPrice" name="retailPrice"  required fullWidth id="retailPrice" color="error" label="Retail Rate" onChange={e => textChange(e)} />
              </Grid> 
            </Grid>
             <Box textAlign="end" m={3}>
                  {updateMode && 
                    <Button type="submit" variant="contained" color="error" style={{marginRight:"10px"}} onClick={() => setStatus(true)}>Cancel</Button>
                 }
              <Button type="submit" variant="contained" color="error" onClick={e => onFormSubmit(e)}>{updateMode ? "Update" : "Save"}</Button>
            </Box>
          </form>
        </Grid>

      </Grid>
      <Box textAlign="center" className={classes.addColor} mb={2} p={2} >
        <Typography variant="h4"> Supplier Design List</Typography>
      </Box>
      <table className='admin-table'>
        <thead>
        <tr>
          <th>Id..</th>
          <th>Metadata</th>
          <th>Supplier Code</th>
          <th>Effective Date</th>
          <th>Design Name</th>
          <th>Default Box Sizes</th>
          <th>Size Range</th>
          <th>WholeSale Rate</th>
          <th>Retail Rate</th>
          <th>Actions</th>

        </tr>
        </thead>
        <tbody>
        {items.map((value, i) => {
          return (

            <tr key={i} >
              <td>{i + 1}</td>
              <td>{value.metaDataName}</td>
              <td>{value.supplierInfo.Value}</td>
              <td>{value.effectiveDate}</td>
              <td>{value.designCode}</td>
              <td>{value.defaultBoxSize}</td>
              <td>{value.size.Value}</td>
              <td>{value.wholesalePrice}</td>
              <td>{value.retailPrice}</td>
              
              <td>
                <Tooltip title="Delete">
                  <IconButton onClick={() => handleDelete(value.id)}><DeleteIcon color="primary" /></IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                  <IconButton onClick={() => handleEdit(value.id)}><EditIcon /></IconButton>
                </Tooltip>
              </td>
            </tr>
          )
        }

        )}
          </tbody>
      </table>
    </>
  )
}

export default SupplierDesign