import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Button,
  Table,
  Box,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  TableContainer,
  Tooltip,
  OutlinedInput,
  FormControl,
  Select,
  MenuItem,
  Autocomplete
} from "@mui/material";
import { LoadingButton } from "@mui/lab"
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  Edit,
  Close,
} from "@mui/icons-material";
import axios from "axios";

import {
  API_HOST,
  lotTableColumnHeaders,
  boxTableColumnHeaders,
  formatPrice
} from "../../../utils/constants"
import { TableLoader, Notification } from "../../../components";
import AddDesignPopUp from "./AddDesignPopUp";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = ({ children, loading, onClose, ...rest }) => {

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...rest}>
      {children}
      {onClose && !loading && (
        <Button
          varaint="text"
          color="secondary"
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            textTransform: "capitalize",
          }}
        >
          <Close />
        </Button>
      )}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const LotInfoTable = ({ lotInfo, sizeData }) => {

  const {
    lotNumber,
    bundleCount,
    totalBoxes,
    sizeIds,
    quantity,
    totalAmount,
  } = lotInfo;

  const formatSizes = () => {
    let sizeValues = sizeIds.split(",").map(size => sizeData[size - 1].sizeValue)
    return `${sizeValues[0]}-${sizeValues[sizeValues.length - 1]}`
  }

  return (
    <>
      <Table sx={{ minWidth: 500 }} >
        <TableHead sx={{ borderBottom: "none" }}>
          <TableRow
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            {lotTableColumnHeaders.map(({ title }) => (
              <TableCell key={title} sx={{ fontWeight: 600, align: "center" }}>
                {title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            key={Math.random()}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            {Object.entries({
              lotNumber,
              bundleCount,
              totalBoxes,
              sizeIds,
              quantity,
              totalAmount: formatPrice(totalAmount),
              "": ""
            }).map(([key, val]) => (
              <TableCell key={key}>
                {key === "sizeIds" ? formatSizes() : val}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </>
  )
}

const BoxRow = ({
  box,
  i,
  designCodes,
  sizes,
  handleChange,
  onClearRow,
  deleteRow,
  btnLoading,
  boxDesignCode
}) => {

  const commonProps = {
    fullWidth: true,
    size: "small",
    type: "number",
    variant: "outlined",
  }

  const [itemValue, setItemValue] = useState({});
  useEffect(() => {
    let selectedValue = designCodes?.filter((eachCode) => {
      if(eachCode.name === box.designCode){
        return eachCode
      }
    });
    setItemValue(selectedValue[0]);
  },[box.designCode])

  return (
    <TableRow
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell key="BoxNo">
        <TextField
          {...commonProps}
          value={box.boxes}
          onChange={(e) => handleChange(i, "boxes", parseInt(e.target.value))}
        />
      </TableCell>
      <TableCell key="DesignCode">
      <Autocomplete
                  // disablePortal
                  id="designCode"
                  options={designCodes}
                  sx={{ width: 225 }}
                  value={itemValue}
                  onChange={(e, value) => {
                    handleChange(i, "designCode", value);
                  }}
                  disabled={!designCodes.length}
                  className="autoCompleteEmp"
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.name
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Design Code" />
                  )}
                />
        {/* <FormControl fullWidth>
          <Select
            value={box.designCode}
            onChange={(e) => {
              handleChange(i, "designCode", e.target.value);
            }}
            disabled={!designCodes.length}
          >
            {designCodes.map((code, id) => (
              <MenuItem
                key={id}
                value={code}
              >
                {code}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
      </TableCell>
      <TableCell key="Size">
        <FormControl fullWidth>
          <Select
            multiple
            value={box.sizeIds}
            onChange={(e) => handleChange(i, "sizeIds", e.target.value)}
            input={<OutlinedInput label="Chip" />}
            renderValue={(selected) => {
              let s = selected.sort()
              if (s.length > 1) return `${s[0]}-${s[s.length - 1]}`
              return s[0]
            }}
            disabled={!sizes.length && !box.sizeIds.length}
            MenuProps={MenuProps}
          >
            {sizes.map(size => (
              <MenuItem
                key={size}
                value={size}
              >
                {size}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TableCell>
      <TableCell key="Rate" align="right">
        {box.ratePerPiece}
      </TableCell>
      <TableCell key="Quantity">
        <TextField
          {...commonProps}
          value={box.quantity}
          onChange={(e) => handleChange(i, "quantity", parseInt(e.target.value))}
        />
      </TableCell>
      <TableCell key="Amount" align="right">
        {formatPrice(box.amount)}
      </TableCell>
      {box.id===0
      ? (
        <TableCell key="BoxTable-Actions" align="right">
          <Button variant="text" onClick={() => onClearRow(i)}>Clear</Button>
        </TableCell>
      ): (
        <TableCell key="BoxTable-Actions" align="right">
          <LoadingButton
            variant="text"
            loading={btnLoading}
            onClick={() => deleteRow(box.id, i)}
          >
            Delete
          </LoadingButton>
        </TableCell>
      )}
    </TableRow>
  )
}

export default function AddBoxPopup({
  lotInfo,
  sizeData,
  open,
  closeModal,
  supplierConstants,
  updateDesignCodesList
}) {

  const {
    id,
    quantity,
    totalAmount
  } = lotInfo

  let defaultValues = useMemo(() => ({
    id: 0,
    lotId: id,
    boxes: 0,
    designCode: "",
    sizeIds: [],
    ratePerPiece: 0,
    quantity: 0,
    amount: 0,
  }), [id])

  const location = useLocation();
  const [loading, setLoading] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)
  const [error, setError] = useState(null);
  const [openToast, setOpenToast] = useState(false);
  const [designCodes, setDesignCodes] = useState([])
  const [sizes, setSizes] = useState([])
  const [boxData, setBoxData] = useState([defaultValues]);
  const [boxDesignCode, setBoxDesignCode] = useState("");
  const [designPopUp, setDesignPopUp] = useState(false);

  const designModel = () => {
    setDesignPopUp(true);
  }

  const designModelClose = () => {
    setDesignPopUp(false);
  }

  const handleChange = (index, key, value) => {
    if (key === "designCode") {
      setBoxDesignCode(value);
      setSizes(
        supplierConstants
          .filter(item => item.designCode === value.name)
          .map(({ sizeValue }) => sizeValue)
      )
      setBoxData(
        boxData.map((b, i) => i === index ? ({
          ...b,
          [key]: value.name,
          sizeIds: [],
          amount: b.quantity * b.ratePerPiece
        }) : b)
      );
    } else if (key === "sizeIds") {
      let val = typeof value === "string" ? value.split(",") : value
      setBoxData(boxData.map(
        (b, i) => i === index ? ({
          ...b,
          [key]: val,
          ratePerPiece: Math.max(
            ...supplierConstants
              .filter(s => s.designCode === b.designCode && val.includes(s.sizeValue))
              .map(w => w.wholesalePrice),
          ),
          amount: Math.max(
            ...supplierConstants
              .filter(s => s.designCode === b.designCode && val.includes(s.sizeValue))
              .map(w => w.wholesalePrice),
          ) * b.quantity
        }) : b
      ))
    } else if (key === "quantity") {
      setBoxData(boxData.map(
        (b, i) => i === index ? ({
          ...b,
          [key]: value,
          amount: parseInt(value) * b.ratePerPiece,
        }) : b
      ))
    } else {
      setBoxData(
        boxData.map((b, i) => i === index ? ({
          ...b,
          [key]: value,
          amount: b.quantity * b.ratePerPiece
        }) : b)
      )
    }
  }

  const onSubmit = async () => {
    setBtnLoading(true)
    try {
      let isQuantity = getTotal("quantity") > quantity
      let isAmount = getTotal("amount") > totalAmount
      if(isQuantity || isAmount) {
        setOpenToast(true)
        setError(
          `Total ${isQuantity? "quantity,": ""}${isAmount? "amount,": ""} of boxes should not exceed ${isQuantity? "quantity,": ""},${isAmount? "amount,": ""} of lot`
        )
      } else {
        let body = boxData
        body = body.map(b => ({
          ...b,
          sizeIds: supplierConstants.filter(
            s => s.designCode===b.designCode && 
            b.sizeIds.includes(s.sizeValue))
              .map(({ sizeId }) => sizeId)
              .join(",")
        }))
        await axios.post(`${API_HOST}/LotDetails`, body)
        setOpenToast(true)
        setError(false)
      }
    } catch (e) {
      setOpenToast(true)
      setError("An error occured while processing your request")
      console.error(e.message)
    } finally {
      setBtnLoading(false)
    }
  }

  const deleteRow = async (id, index) => {
    try {
      setBtnLoading(true)
      await axios.delete(`${API_HOST}/LotDetails`, { params: { lotDetailsId: id } })
      onClearRow(index)
      setBtnLoading(false)
      setOpenToast(true)
      setError(false)
    } catch(e) {
      setBtnLoading(false)
      setOpenToast(true)
      setError(e.message)
    }
  }

  const onClearRow = (index) => {
    setBoxData(box => box.filter((_, i) => i!==index))
  }

  const getBoxes = useCallback(async () => {
    try{
      setLoading(true)
      const { data } = await axios.get(`${API_HOST}/LotDetails`, {
        params: { lotId: id }
      })
      setBoxData(
        data.map(b => ({
          ...b,
          sizeIds: b.sizeIds.split(",").map(s => sizeData[parseInt(s)-1].sizeValue)
        }))
      )
      setLoading(false)
    } catch(e) {
      setLoading(false)
      setOpenToast(true)
      setError(e.message)
    }
  }, [id, sizeData])

  useEffect(() => {
    let filtered = Array.from([...new Set(supplierConstants.map(a => a.designCode))])
    // setDesignCodes(filtered);
    let newCodes = filtered?.map((eachItm, index)=>{
      return {id : index + 1, name: eachItm}
    })
    setDesignCodes(newCodes);
  },[supplierConstants])

  useEffect(() => {
    getBoxes()
  }, [getBoxes])

  const getTotal = (key) => {
    if(boxData.length === 0 || typeof boxData[0][key] !== "number") return 0
    let val = boxData.length > 1
    ? boxData.map(b => b[key]).reduce((prev, curr) => prev+curr)
    : boxData[0][key]

    return !isNaN(val)? val: 0
  }

  return (
      <>
        {location.pathname === "/inventory-management" && (
          <Tooltip
            title="Edit Details"
          >
            <IconButton size="small" aria-label="edit" onClick={() => { }}>
              <Edit />
            </IconButton>
          </Tooltip>
        )}
        <Notification
          open={openToast}
          onClose={() => setOpenToast(false)}
          error={error}
        />
        {designPopUp && 
        <AddDesignPopUp designPopUp={designPopUp} designModelClose={designModelClose} updateDesignCodesList={updateDesignCodesList} />
        }
        <BootstrapDialog
          sx={{ "& .MuiDialog-paper": { md: { maxWidth: "90%", width: "100%" } } }}
          onClose={closeModal}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            sx={{ fontWeight: 600 }}
            onClose={closeModal}
          >
            Lot No. {id}
          </BootstrapDialogTitle>
          <DialogContent>
            <TableContainer>
              <LotInfoTable {...{ lotInfo, sizeData }} />
            </TableContainer>
            <TableContainer style={{ maxHeight: "400px" }}>
              {/* Boxes data */}
              {loading
                ? <TableLoader />
                : (
                  <>
                    <Table width={100}>
                      <TableHead sx={{ bgcolor: "#5b6266" }}>
                        <TableRow>
                          {boxTableColumnHeaders.map(({ title, align, width }) => (
                            <TableCell
                              key={title}
                              sx={{ color: "white", fontWeight: 600, align: align || "left", width }}
                            >
                              {title}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {boxData.map((box, i) => (
                          <BoxRow
                            key={i}
                            {...{
                              box,
                              i,
                              designCodes,
                              sizes,
                              handleChange,
                              onClearRow,
                              deleteRow,
                              btnLoading,
                              boxDesignCode
                            }}
                          />
                        ))}
                      </TableBody>
                      <TableHead sx={{ bgcolor: "#5b6266" }}>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell sx={{ color: "white", fontWeight: 600 }}
                          >
                            Total: {getTotal("quantity")}
                          </TableCell>
                          <TableCell sx={{ color: "white", fontWeight: 600, textAlign: "center" }}>
                            Total: {formatPrice(getTotal("amount"))}
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                    <Box>
                      <Button
                        variant="text"
                        onClick={() => setBoxData(box => [...box, defaultValues])}
                      >
                        Add new box
                      </Button>
                    </Box>
                  </>
                )}
              {/* Boxes data */}
            </TableContainer>
          </DialogContent>
          <Box my={2} sx={{ textAlign: "center" }}>
          <Button variant="text" color="secondary" onClick={designModel} sx={{ px: 5, mx: 1 }}>
              Add New Design
            </Button>
            <Button variant="text" color="secondary" onClick={closeModal} sx={{ px: 5, mx: 1 }}>
              Cancel
            </Button>
            <LoadingButton
              loading={btnLoading}
              onClick={onSubmit}
              variant="contained"
              sx={{ px: 3 }}
            >
              Save
            </LoadingButton>
          </Box>
        </BootstrapDialog>
      </>
    );
}
