import { Routes, Route } from "react-router-dom";
// import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';


function Admin() {

    const history = useNavigate();

    const handleClick = (path) => {
        history(path);
    };

    return (
        <>
            <div className='App'>
                <div className='page'>
                    <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group">
                            <FormControlLabel value="supplier" onClick={() => handleClick("/supplier")} control={<Radio />} label="Supplier" />
                            <FormControlLabel value="transporter" onClick={() => handleClick("/transporter")} control={<Radio />} label="Transporter" />
                            <FormControlLabel value="godown" onClick={() => handleClick("/godown")} control={<Radio />} label="Godown" />
                            <FormControlLabel value="retailer" onClick={() => handleClick("/retailer")} control={<Radio />} label="Dealer" />
                            <FormControlLabel value="store" onClick={() => handleClick("/store")} control={<Radio />} label="Store" />
                            <FormControlLabel value="companyInfo" onClick={() => handleClick("/companyInfo")} control={<Radio />} label="CompanyInfo" />
                            <FormControlLabel value="users" onClick={() => handleClick("/users")} control={<Radio />} label="Users" />
                            <FormControlLabel value="category" onClick={() => handleClick("/category")} control={<Radio />} label="Category" />
                            <FormControlLabel value="supplierDesign" onClick={() => handleClick("/supplierdesign")} control={<Radio />} label="SupplierDesign" />
                            <FormControlLabel value="partyRates" onClick={() => handleClick("/partyrates")} control={<Radio />} label="PartyRates" />
                            <FormControlLabel value="sizeRate" onClick={() => handleClick("/sizerate")} control={<Radio />} label="SizeRate" />

                        </RadioGroup>
                    </FormControl>

                </div>

            </div>
            <br />
        </>
    )
}

export default Admin;