import React from "react";
import { Typography } from "@mui/material";

import { STORAGE_KEY } from "../../utils/constants"

export default function Home() {
  const name = JSON.parse(localStorage.getItem(STORAGE_KEY)).loginName

  return (
    <div>
      <Typography variant="h5" color="text.primary">
        Welcome, {name}
      </Typography>
    </div>
  );
}
