import React from "react";
import PropTypes from "prop-types";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
  Button
} from "@mui/material";
import { Menu, Logout } from "@mui/icons-material";

import { STORAGE_KEY } from "../../utils/constants"

const drawerWidth = 200;
const listItems = [
  {
    name: "Home",
    href: "/",
  },
  {
    name: "Received Inventory",
    href: "/received-inventory",
  },
  // {
  //   name: "Distribute Inventory",
  //   href: "/distribute-inventory",
  // },
  {
    name: "Inventory Management",
    href: "/inventory-management",
  },
  {
    name: "Admin",
    href: "/admin",
  },
  {
    name: "Payment",
    href: "/payment",
  },
];

function Layout() {
  const navigate = useNavigate();
  const location = useLocation();
  // const ADMIN_LINK = "https://soko.co.in/wp-admin";
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [active, setActive] = React.useState(location.pathname);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {listItems.map((val, index) => (
          <ListItem
            button
            onClick={() => {
              setActive(val.href);
              navigate(val.href);
            }}
            sx={{
              bgcolor: active === val.href ? "#0033a0" : "",
              color: active === val.href ? "#fff" : "",
              "&:hover": {
                bgcolor: "#84a0dd",
                color: "#fff",
              },
            }}
            key={index}
          >
            <ListItemText primary={val.name} />
          </ListItem>
        ))}
      </List>
      <Divider />
    </div>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        color="primary"
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <Menu />
          </IconButton>
          <Typography
            variant="h6"
            sx={{ fontWeight: 600 }}
            noWrap
            component="div"
          >
            {location.pathname === "/payment" ? "BNI" : "Kumar Shirts & Trousers"}
          </Typography>
          <Box ml="auto">
            {/* <Button
              href={ADMIN_LINK}
              target="_blank"
              variant="text"
              focusRipple
              sx={{ fontSize: 18, color: "white", mr: 2 }}
            >
              Admin
            </Button> */}
            <Button
              onClick={() => {
                localStorage.removeItem(STORAGE_KEY);
                navigate("/login");
              }}
              variant="contained"
              color="info"
            >
              <Logout />
              logout
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="permanent"
          sx={{
            // display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: `calc(100% - ${drawerWidth}px)`,
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}

Layout.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Layout;
