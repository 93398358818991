import React, { useState, useEffect, useCallback, useRef } from 'react'
import axios from "axios"
import { useReactToPrint } from "react-to-print"
import {
  Typography,
  Box,
  Button,
  TextField, 
  Stack,
  Autocomplete,
  Grid
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Notification } from "../../../components"
import { API_HOST, invoiceTableHeaders } from "../../../utils/constants";
import InvoiceComponent from './InvoiceComponent';
import { STORAGE_KEY } from "../../../utils/constants";

export default function PackingSlip() {
  const userName= JSON.parse(localStorage.getItem(STORAGE_KEY)).loginName
  // const transporters = ["1", "2", "trasCode"]
  // var staticHeaders = ["designCode", "pieces", "boxCount", "rate", "RR", "amount"];
  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Invoice",
  })

  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [openToast, setOpenToast] = useState(false)
  const [parties, setParties] = useState([])
  const [transporter, setTransporter] = useState("");
  const [printData, setPrintData] = useState({});
  const [categories, setCategories] = useState("");
  const [godowns, setGodowns] = useState();
  const [partyName, setPartyName] = useState();
  const [categoryId, setCategoryId] = useState();
  const [godown, setGodown] = useState();
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [discount, setDiscount] = useState(0);
  const [freight, setFreight] = useState(0);
  const [invoicePrinted, setInvoicePrinted] = useState(false);
  const [transError ,setTransError] = useState(false);
  const [refNumber, setRefNumber] = useState("");
  const [dummyArr, setDummyArr] = useState([]);
  const [invHeaders, setInvHeaders] = useState([]);
  const [transporterData, setTransporterData] = useState([]);
  const [store, setStore] = useState("");
  const [storeData, setStoreData] = useState([]);
  function prepareHeaders(resColumns){
    let cols = resColumns.map((each) => {
      let upperString = each.charAt(0).toUpperCase() + each.slice(1);
      return {
          field: each,
          headerName: upperString.split(/(?=[A-Z])/).join(" "),
          flex: 1
      }
    })
    cols.push({ field: "id", headerName: "ID", flex: 1, hide:true },
              { field: "packingslipid", headerName: "Packing Slip ID", flex: 1 });
    setColumns(cols)
  }
  var row = [];
  const fetchConstants = useCallback(async () => {
    setLoading(true)
    try {
      await axios.get(`${API_HOST}/Constants/partyList`).then((res) => {
        setParties(res.data);
      });
      await axios.get(`${API_HOST}/Constants/`).then((res) => {
        setCategories(res.data.categoryData);
        setGodowns(res.data.godownData);
      });
      await axios.get(`${API_HOST}/Transporters`).then((res) => {
        setTransporterData(res.data);
      });
      await axios.get(`${API_HOST}/Stores`).then((res) => {
        setStoreData(res.data);
      });
      setLoading(false);
    } catch(e) {
      setOpenToast(true)
      setError(e.message)
      console.error(e.message)
    }
    setLoading(false)
  }, [])

  const onSubmit = async () => {
    if(!transporter){
      setTransError(true);
      alert("Please select transporter and generate invoice.");
    } else { 
      const rIds = selectedRows.map(r => {return r.id});
      // const userName= JSON.parse(localStorage.getItem(STORAGE_KEY)).loginName
      const postData = {
        "discount": parseInt(discount),
        "transporterCode": transporter.code,
        "storeCode": store.code,
        "userName": userName,
        "freight" : parseInt(freight),
        "packagingSlipIds": rIds
      };
      const postInvoiceData = axios.post(`${API_HOST}/InvoiceGeneration/InvoiceSummary/PostInvoice`,postData);
      postInvoiceData.then(res => {
        let recData = res.data;
        // var reqHeaders = [];
        recData.packingSlipViewForInvoice.data.map((eachItem, index) => {
          for (const property in eachItem.sizeCount) {
            // reqHeaders.push(property);
            recData.packingSlipViewForInvoice.data[index][property.trim()] = eachItem.sizeCount[property];
          }
        });
        let dArr = []
        for(var i=1; i<= 14-recData.packingSlipViewForInvoice.data.length; i++) {
          dArr.push(i.toString());
        }
        // console.log(staticHeaders.concat(reqHeaders.sort()), reqHeaders);
        setInvHeaders([...new Set(recData.packingSlipViewForInvoice?.headers)]);
        setDummyArr(dArr);
        setPrintData(recData);
        setOpenToast(true);
        setError(false);
      }).catch(err => {
        setOpenToast(true)
        setError(err.message)
        console.error(err.message)
      });
      // console.log("selee", postData);
      // setLoading(true)
      // try {
      //   setOpenToast(true)
      //   setError(false)
      //   handlePrint()
      // } catch (e) {
      //   setOpenToast(true)
      //   setError(e.message)
      //   console.error(e.message)
      // }
      // setLoading(false)
    }
  }

  // filter function
  const searchHandler = async () => {
    setInvoicePrinted(false);
    try {
      if (!partyName || !categoryId || !godown)
        alert("Kindly provide party name, category and godown !!!");
      else {
        axios
          .get(
            `${API_HOST}/InvoiceGeneration/InvoiceSummary/${categoryId.categoryId}/${partyName.code}/${godown.godownCode}/${userName}`
          )
          .then((res) => {
            if (res && res.data.data.length > 0) {
              // for (const iterator in res.data[0].sizeCount) {
              //   cols.push({ field: iterator, headerName: iterator, width: 70 });
              // }
              // res.data.headers?.map((sizeHeaders) => {
              //   cols.push({ field: sizeHeaders.trim(), headerName: sizeHeaders.trim(), flex: 1 })
              // })
              prepareHeaders(res.data.headers);
              // setColumns(cols);
              for (let i = 0; i < res.data.data.length; i++) {
                let data = res.data.data[i];
                row.push({
                  id: data.id,
                  packingslipid:data.packingslipid,
                  invoiceNumber:data.invoiceNumber,
                  designCode: data.designCode,
                  // pieces: findSum(data.sizeCount),
                  partyName:data.partyName,
                  boxCount: data.boxCount,
                  taxAmount:data.taxAmount,
                  retailPrice:data.retailPrice,
                  total:data.total,
                  grandTotal:data.grandTotal,
                  ...data.sizeArr,
                  ...data.sizeCount,
                });
              }
              setRows(row);
            }
          });
      }
    } catch (error) {}
  };

  const findSum = (obj) => {
    let sum = 0;
    for (let property in obj) {
      sum += isNaN(Number(obj[property])) ? 0 : Number(obj[property]);
    }
    return sum;
  };

  useEffect(()=>{
    if(invoicePrinted){
      setPrintData([]);
      setSelectedRows([]);
      setDiscount(0);
      setFreight(0);
      setTransporter("");
      setStore("");
      setRows([]);
      setRefNumber("");
    }
  },[invoicePrinted])

  useEffect(() => {
    if(Object.entries(printData).length >0){
      handlePrint();
      setInvoicePrinted(true);
    }
  },[printData]);

  useEffect(() => {
    fetchConstants()
  }, [fetchConstants])

  return (
    <>
      <Notification
        open={openToast}
        onClose={() => setOpenToast(false)}
        error={error}
        success="Generating Invoice"
      />
      <Typography variant="h5" color="text.primary" mb={2}>
        OpenPacking Slips
        <br/>
        <Typography variant="caption" color="dodgerblue" mb={2}>
          * Generate invoice easily using below form
        </Typography>
      </Typography>
      <Box mb={2}>
      <Stack direction="row" spacing={2}>
          <div className="form-inline">
            <div className="inputDiv">
              {loading ? (
                "Loading"
              ) : (
                <Autocomplete
                  disablePortal
                  id="party"
                  options={parties}
                  sx={{ width: 300 }}
                  onChange={(e, value) => setPartyName(value)} // prints the selected value
                  value={partyName}
                  // className="autoCompleteEmp"
                  // getOptionSelected={(option, value) =>
                  //   option.name === value.name
                  // }
                  getOptionLabel={(option) => {
                    return typeof option === "string" ? option : `${option.code} - ${option.name}`
                  }
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Party Name" />
                  )}
                />
              )}
            </div>
            <div className="inputDiv">
              {loading ? (
                "Loading"
              ) : (
                <Autocomplete
                  disablePortal
                  id="category"
                  options={categories}
                  sx={{ width: 300 }}
                  onChange={(event, value) => setCategoryId(value)} // prints the selected value
                  value={categoryId}
                  className="autoCompleteEmp"
                  // getOptionSelected={(option, value) =>
                  //   option.categoryName === value.categoryName
                  // }
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.categoryName
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Category" />
                  )}
                />
              )}
            </div>
            <div className="inputDiv">
              {loading ? (
                "Loading"
              ) : (
                <Autocomplete
                  disablePortal
                  id="godown"
                  options={godowns}
                  sx={{ width: 300 }}
                  onChange={(event, value) => setGodown(value)} // prints the selected value
                  value={godown}
                  className="autoCompleteEmp"
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.godownName
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Godown" />
                  )}
                />
              )}
            </div>
          </div>
          <Button variant="contained" onClick={searchHandler}>
            Search
          </Button>
          {/* <Button variant="contained" onClick={reset}>
            Reset
          </Button> */}
        </Stack>
        </Box>
       <Stack direction="row" spacing={2}>
          <div style={{ height: 400, width: "100%" }}>
            {rows.length > 0 && (
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={100}
                rowsPerPageOptions={[1]}
                checkboxSelection
                hideFooterPagination
                onSelectionModelChange={(ids) => {
                  const selectedIDs = new Set(ids);
                  const selectedRows = rows.filter((row) =>
                    selectedIDs.has(row.id)
                  );
                  setSelectedRows(selectedRows);
                }}
              />
            )}
            <br />
          </div>
        </Stack>
        <br /><br />
        {rows.length > 0 &&
        <Grid container spacing={2}>
          {/* <Box mb={2}>
            <Stack direction="row" spacing={2}> */}
            <Grid item lg={4} md={8} sm={12} xs={12}>
            <Autocomplete
              disablePortal
              id="transporter"
              options={transporterData}
              sx={{ width: 300 }}
              onChange={(event, value) => {setTransporter(value); setTransError(false)}} // prints the selected value
              value={transporter}
              className="autoCompleteEmp"
              // getOptionLabel={(option) =>
              //   option
              // }
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.name
              }
              renderInput={(params) => (
                <TextField {...params} error={transError} label="Transporters" />
              )}
            />
            </Grid>
            <Grid item lg={4} md={8} sm={12} xs={12}>
            <Autocomplete
              disablePortal
              id="store"
              options={storeData}
              sx={{ width: 300 }}
              onChange={(event, value) => {setStore(value); setTransError(false)}} // prints the selected value
              value={store}
              className="autoCompleteEmp"
              // getOptionLabel={(option) =>
              //   option
              // }
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.name
              }
              renderInput={(params) => (
                <TextField {...params} error={transError} label="Store" />
              )}
            />
            </Grid>
            <Grid item lg={4} md={8} sm={12} xs={12}>
            <TextField 
              id="outlined-basic"
              label="Discount" 
              variant="outlined"
              type="number"
              sx={{ width: 300 }}
              value={discount}
              onChange={(e) => setDiscount(e.target.value)} 
            />   
            </Grid> 
            <Grid item lg={4} md={8} sm={12} xs={12}>
            <TextField 
              id="outlined-basic"
              label="Freight" 
              variant="outlined"
              type="number"
              sx={{ width: 300 }}
              value={freight}
              onChange={(e) => setFreight(e.target.value)} 
            />   
            </Grid>
            <Grid item lg={4} md={8} sm={12} xs={12}>
            <TextField 
              id="outlined-basic"
              label="Reference Number" 
              variant="outlined"
              type="text"
              sx={{ width: 300 }}
              value={refNumber}
              onChange={(e) => setRefNumber(e.target.value)} 
            />   
            </Grid> 
            <Grid item lg={4} md={8} sm={12} xs={12}>
              {/* {selectedRows && selectedRows.length > 0 && ( */}
                <Button 
                  variant="contained" 
                  onClick={onSubmit} 
                  disabled={selectedRows.length <= 0}
                  style={{width:"200px",height:"48px" }}>
                  Generate Invoice
                </Button>
              {/* )} */}
            </Grid>
            {/* </Stack>
          </Box> */}
          </Grid>
        }
      <div style={{display: "none"}}>
        <InvoiceComponent ref={componentRef} printData={printData} refNumber={refNumber} dummyArr={dummyArr} headers={invHeaders} />
      </div>
    </>
  )
}