import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
// mui
import { Typography, Grid } from "@mui/material";

// ________________
import {
  AddInventoryForm,
  AddLotForm,
  LotTable
} from "./components";
import { TableLoader } from "../../components";
import { API_HOST } from "../../utils/constants";

export default function Inventory() {
  const [loading, setLoading] = useState(false)
  const [openLotInfo, setOpenLotInfo] = useState(false);
  const [autoSizes, setAutoSizes] = useState(null);
  const [catId, setCatId] = useState("");
  const [constants, setConstants] = useState(null)
  const [inventoryData, setInventoryData] = useState(null)
  const [count, setCount] = useState(0)
  const [lotDetails, setLotDetails] = useState(null)
  const [total, setTotal] = useState({
    amount: 0,
    quantity: 0
  })

  useEffect(async () => {
    if(catId) { 
      const { data } = await axios.get( `${API_HOST}/Constants/GetSizeByCategoryId?categoryId=${catId}`);
      setAutoSizes(data);
    }
  }, [catId])

  const getConstants = useCallback(async () => {
    try{
      setLoading(true)
      const { data } = await axios.get(`${API_HOST}/Constants`)
      setConstants(data)
      setLoading(false)
    } catch(err) {
      console.log(err.message);
    }
  }, [])

  useEffect(() => {
    getConstants()
  }, [getConstants]);
  
  return (
    <>
      <Typography variant="h5" color="text.primary" mb={2}>
        Received Inventory
        <br/>
        <Typography variant="caption" color="dodgerblue" mb={2}>
          * Changes will not be saved upon changing tabs or refreshing pagee
        </Typography>
      </Typography>
      {(!loading && constants)
      ? (
        <AddInventoryForm
          constants={constants}
          setOpenLotInfo={setOpenLotInfo}
          setInventoryData={setInventoryData}
          setCatId={setCatId}
        />
      )
      : (
        <Grid container spacing={3} justifyContent="space-between">
          {Array(3).fill(0).map((_, i) => (
            <Grid item lg={4} md={4} sm={6} xs={12} key={i}>
              <TableLoader barCount={4} height="40px" />
            </Grid>
          ))}
        </Grid>
      )}
      {(openLotInfo && inventoryData) && (
        <>
          <AddLotForm
            data={inventoryData}
            onCount={() => setCount(count+1)}
            sizes={autoSizes}
            total={total}
            setLotDetails={setLotDetails}
          />
          <LotTable
            sizes={constants?.sizeData || []}
            data={inventoryData}
            lotDetails={lotDetails}
            total={total}
            setTotal={setTotal}
            count={count}
          />
        </>
      )}
    </>
  );
}
