import React, { useCallback, useState, useEffect } from "react";
import axios from "axios";

import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Chip,
  Paper,
  Switch,
  Typography
} from "@mui/material";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Edit
} from "@mui/icons-material";

import { InventoryBoxPopup, ReportDamage } from "../components"
import { TableLoader, Notification } from "../../../components";
import { AddBoxPopup } from "../../ReceivedInventory/components"
import {
  API_HOST,
  invMgmtTableColumnHeaders,
  invMgmtInsideTableLabels,
  formatPrice,
  PAGE_LIMIT
} from "../../../utils/constants";

const SubRow = ({
  id,
  stiNumber,
  data,
  row,
  sizes,
  updated,
  setUpdated,
  setOpenReport,
  setOpenBoxPopup,
  setRowData,
  setSupplierCode,
  setOpenLotPopup,
  setBoxPopupData,
  setReportData,
  setNotification
}) => {
  const [supplierConstants, setSupplierConstants] = useState([]);
  const [lotDetails, setLotDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchLotDetails = useCallback(async (source) => {
    setLoading(true)
    try {
      const { data } = await axios.get(`${API_HOST}/LotDetails/${id}`, {
        cancelToken: source.token,
        timeout: 15000
      })
      setLotDetails(data)
    } catch (e) {
      console.error(e.message)
    }
    setLoading(false)
  }, [id, row.supplierCode])

  const fetchLotInfo = useCallback(async (id) => {
    try {
      const [
        { data },
        { data: lot },
      ] = await Promise.all([
        axios.get(`${API_HOST}/Constants/SupplierDesignCode/all`),
        axios.get(`${API_HOST}/Lot/${id}`)
      ])
      setSupplierConstants(data);
      return lot;
    } catch (e) {
      console.log(e.message)
    }
  }, [])

  useEffect(async() => {
    const [
      { data },
    ] = await Promise.all([
      axios.get(`${API_HOST}/Constants/SupplierDesignCode/all`)
    ])
    setSupplierConstants(data);
  },[])

  const changeReceivedStatus = async (id, isRecieved) => {
    try {
      const { data } = await axios.patch(`${API_HOST}/LotDetails/${id}/${isRecieved}`)
      setLotDetails(data)
      setNotification({ open: true })
      setUpdated(!updated)
    } catch (e) {
      console.log(e.message)
      setNotification({
        open: true,
        error: e.message
      })
    }
  }

  const getHistoryDiffColor = useCallback((value) => {
    const colors = {
      4: "#FF1844",
      2: "grey"
    }

    return colors[value] ?? "green"
  }, [])

  const getSizes = useCallback((value) => {
    const s = value?.split(",")?.map(
      v => (sizes?.filter(s => s.id === parseInt(v))[0]?.sizeValue)
    )
    s.sort()
    return s.length>1? `${s[0]}-${s[s.length - 1]}`: s[0]
  }, [sizes])

  useEffect(() => {
    let unmounted = false;
    let source = axios.CancelToken.source();
    fetchLotDetails(source)

    return () => {
      unmounted = true;
      source.cancel("Cancelling in cleanup");
    }
  }, [fetchLotDetails, data])

  return (
    <TableContainer>
      <Table sx={{ minWidth: 800 }} aria-label="purchases">
        <TableHead
          sx={{ bgcolor: "#0DA1DA", "& th": { color: "#fff" } }}
        >
          <TableRow>
            {invMgmtInsideTableLabels.map((title, i) => (
              <TableCell key={i}>{title}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data && !loading
            ? data.map((subRow) => (
              <TableRow key={Math.random()}>
                <TableCell>
                  {subRow.lotNumber}
                </TableCell>
                <TableCell
                  sx={{
                    color: "#0DA1DA",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={subRow?.isRecieved
                    ? () => {
                      setOpenBoxPopup(true)
                      setRowData(subRow)
                      setSupplierCode(row.supplierCode)
                    } : () => null
                  }
                >
                  {subRow.boxes}
                </TableCell>
                <TableCell>{subRow.designCode}</TableCell>
                <TableCell>{subRow.sizeIds && getSizes(subRow.sizeIds)}</TableCell>
                <TableCell>{subRow.ratePerPiece}</TableCell>
                <TableCell>{subRow.quantity}</TableCell>
                <TableCell
                  sx={{
                    color: getHistoryDiffColor(subRow.difference),
                    textDecoration: "underline",
                    cursor: "pointer"
                  }}
                  onClick={
                    subRow.difference ?
                    () => {
                      setOpenReport(true)
                      setReportData({
                        id : subRow.lotDetailId,
                        stiNumber,
                        quantity: subRow.quantity,
                        lotNumber: subRow.lotNumber,
                        difference: subRow.difference
                      })
                    } : null
                  }
                >
                  {subRow.difference}
                </TableCell>
                <TableCell>{formatPrice(subRow.amount)}</TableCell>
                <TableCell>
                  <Switch
                    checked={subRow?.isRecieved}
                    disabled={subRow?.isRecieved}
                    onChange={e => changeReceivedStatus(subRow?.lotDetailId, e.target.checked)}
                  />
                </TableCell>
                <TableCell>
                  <Edit
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={!subRow?.isRecieved ? async () => {
                      setOpenLotPopup(true)
                      setBoxPopupData({
                        supplierConstants,
                        lotInfo: await fetchLotInfo(subRow.lotId)
                      })
                    } : null}
                  />
                </TableCell>
              </TableRow>
            )
            ) : <Typography>Just a second&#128337;...</Typography>}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const Row = ({
  row,
  categories,
  sizes,
  setOpenReport,
  setOpenBoxPopup,
  setRowData,
  setSupplierCode,
  setOpenLotPopup,
  setBoxPopupData,
  setReportData,
  setNotification,
  setCatId
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [updated, setUpdated] = useState(false);

  const fetchInvoice = async () => {
    try {
      const { data } = await axios.get(`${API_HOST}/Invoice/${row?.id}`)
      setData(data)
    } catch (e) {
      console.log(e.message)
    }
  }

  const onOpen = async () => {
    setLoading(true);
    try {
      if (!open) {
        await fetchInvoice()
        setOpen(true)
      } else {
        setOpen(false);
      }
    } catch (e) {
      console.error(e.message)
    }
    setLoading(false)
  };

  const getTagColor = useCallback((value) => {
    const tagColors = {
      New: "info",
      Partial: "warning",
    }
    return tagColors[value] ?? "success"
  }, [])

  const getCategory = useCallback((value) => {
    setCatId(value);
    return categories?.filter(c => c.categoryId === value)[0]?.categoryName
  }, [categories])

  useEffect(() => {
    if(open) {
      fetchInvoice()
    }
  }, [updated])
  

  return (
    <>
      <TableRow
        sx={{
          "& > *": { borderBottom: "unset" },
        }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => onOpen()}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{row.id}</TableCell>
        <TableCell>{row.invoiceNumber}</TableCell>
        <TableCell>{row.supplierCode}</TableCell>
        <TableCell>{getCategory(row.categoryId)}</TableCell>
        <TableCell>{row.lrNumber}</TableCell>
        <TableCell>{formatPrice(row.totalAmount)}</TableCell>
        <TableCell>{new Date(row.receivedDate).toLocaleString()}</TableCell>
        <TableCell>
          {" "}
          <Chip
            size="small"
            label={row.receivedStatus || "New"}
            color={getTagColor(row.receivedStatus)}
          />
        </TableCell>
        <TableCell>
          <Chip
            size="small"
            label={row.distributedStatus || "New"}
            color={getTagColor(row.distributedStatus)}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ pt: 0, pb: 0 }} colSpan={10}>
          {loading ? (
            <Box p={2}><TableLoader barCount={2} /></Box>
          ) : (
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box>
                <SubRow
                  {...{
                    id: row.id,
                    stiNumber: row.invoiceNumber,
                    data,
                    row,
                    sizes,
                    updated,
                    setUpdated,
                    setOpenReport,
                    setOpenBoxPopup,
                    setRowData,
                    setSupplierCode,
                    setOpenLotPopup,
                    setBoxPopupData,
                    setReportData,
                    setNotification
                  }}
                />
              </Box>
            </Collapse>
          )}
        </TableCell>
      </TableRow>
    </>
  );
}

export default function InventoryManagementTable(props) {

  const [openReport, setOpenReport] = useState(false)
  const [notification, setNotification] = useState({
    open: false,
    error: null
  })
  const [reportData, setReportData] = useState(null)
  const [openBoxPopup, setOpenBoxPopup] = useState(false)
  const [openLotPopup, setOpenLotPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invoicedata, setInvoicedata] = useState([]);
  const [constants, setConstants] = useState([]);
  const [rowData, setRowData] = useState(null)
  const [supplierCode, setSupplierCode] = useState(null)
  const [boxPopupData, setBoxPopupData] = useState(null);
  const [page, setPage] = useState(0);
  const [catId, setCatId] = useState("");

  const fetchAllData = useCallback(async () => {
    setLoading(true)
    try {
      const [{ data: invoices }, { data: constants }] = await Promise.all([
        axios.get(`${API_HOST}/PurchaseInvoice`, {
          params: { limit: PAGE_LIMIT, offset: page * PAGE_LIMIT, isReserved: props.reserved ? true : false }
        }),
        axios.get(`${API_HOST}/Constants`)
      ])
      setInvoicedata(invoices)
      setConstants(constants)
    } catch (e) {
      console.log(e.message)
    }
    setLoading(false)
  }, [page])

  useEffect(() => {
    fetchAllData()
  }, [page, fetchAllData]);

  return (
    <>
      <Notification
        open={notification.open}
        error={notification.error}
        onClose={() => setNotification({ ...notification, open: false })}
      />
      <TableContainer component={Paper}>
        {loading
          ? <TableLoader barCount={12} />
          : (
          <Table sx={{ minWidth: 800 }} aria-label="a dense table">
            <TableHead>
              <TableRow sx={{ bgcolor: "#0DA1DA", "& th": { color: "#fff" } }}>
                {invMgmtTableColumnHeaders.map((title, i) => (
                  <TableCell key={i}>{title}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {invoicedata?.result?.map((row, i) => (
                <Row
                  key={i}
                  {...{
                    row,
                    categories: constants?.categoryData,
                    sizes: constants?.sizeData,
                    setOpenReport,
                    setOpenBoxPopup,
                    setRowData,
                    setSupplierCode,
                    setOpenLotPopup,
                    setBoxPopupData,
                    setReportData,
                    setNotification,
                    setCatId
                  }}
                />
              ))}
            </TableBody>
          </Table>
        )}
        <TablePagination
          component="p"
          rowsPerPage={10}
          rowsPerPageOptions={[10]}
          count={invoicedata?.totalResults || 0}
          page={page}
          labelDisplayedRows={({ from, to, count }) => `${(count-from+1) || 0}-${(count-to+1) || 0} of ${count !== -1 ? (count || 0) : `more than ${count-to+1}`}`}
          onPageChange={(_, value) => setPage(value)}
        />
        {openReport && (
          <ReportDamage
            openModal={openReport}
            setOpenModal={setOpenReport}
            reportData={reportData}
          />
        )}
        {openBoxPopup && supplierCode && (
          <InventoryBoxPopup
            open={openBoxPopup}
            supplierCode={supplierCode}
            onClose={() => setOpenBoxPopup(false)}
            row={rowData}
            catId={catId}
          />
        )}
        {openLotPopup && constants && boxPopupData && (
          <AddBoxPopup
            lotInfo={boxPopupData?.lotInfo}
            sizeData={constants?.sizeData}
            open={openLotPopup}
            closeModal={() => setOpenLotPopup(false)}
            supplierConstants={boxPopupData?.supplierConstants}
          />
        )}
      </TableContainer>
    </>
  );
}
