import React, { useState } from "react";
import PropTypes from "prop-types";

// mui
import {
  Tabs,
  Tab,
  Typography,
  Box
} from "@mui/material";

// _____________________
import { InvMgmtTable, PackingSlip, Invoice } from "./components";
import { useTheme } from "@mui/material/styles";


function TabPanel({ children, value, index, ...rest }) {

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...rest}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function InventoryManagement() {

  const theme = useTheme();
  const [tab, setTab] = useState(0);

  const handleChange = (_, newValue) => setTab(newValue);

  return (
    <div>
      <Box
        sx={{
          width: "100%",
          "& #simple-tabpanel-0 > .MuiBox-root": {
            padding: "24px 0px!important",
          },
        }}
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Tabs
            value={tab}
            onChange={handleChange}
            aria-label="inventory-management-area"
            sx={{
              "& .MuiTab-root": {
                color: theme.palette.text.primary.main,
                "&.Mui-selected": {
                  color: theme.palette.error.main,
                },
              },
              "& .MuiTabs-indicator": {
                bgcolor: theme.palette.error.main,
              },
            }}
          >
            <Tab label="Received Inventory" {...a11yProps(0)} />
            <Tab label="Reserved Inventory" {...a11yProps(1)} />
            <Tab label="Distribution Inventory- Packing Slip" {...a11yProps(2)} />
            <Tab label="Distribution Inventory- Invoice" {...a11yProps(3)} />
          </Tabs>
        </Box>
        <TabPanel value={tab} index={0}>
          <InvMgmtTable />
        </TabPanel>
        <TabPanel value={tab} index={1}>
        <InvMgmtTable reserved={true} />
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <PackingSlip />
        </TabPanel>
        <TabPanel value={tab} index={3}>
          <Invoice />
        </TabPanel>
      </Box>
    </div>
  );
}
