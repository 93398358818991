import { render } from "@testing-library/react";
import React from "react";
import logo from "../../../img/Invoice.jpeg";
import "../styles/invoice.css"

export default class InvoiceComponent extends React.Component {
    getCategoryData(cData){
        let catData = [];
        for (const [key, value] of Object.entries(cData)) {
            let keyVal = key.charAt(0).toUpperCase() + key.slice(1);
           catData.push(<><strong>{keyVal.replace(/([a-z])([A-Z])/g, '$1 $2')}   : {value}</strong><br /></>);
          }
          return catData;
    };
    getInvoiceDate(){
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const d = new Date();
        let month = months[d.getMonth()];
        return `${d.getDate()}-${month}-${d.getFullYear()}`;
    }
    getHeaders(tHeader){
        if(tHeader === "RR") {
            return tHeader;
        } else if(typeof tHeader == "number") {
            return tHeader;
        }
        else {
            let upperString = tHeader?.charAt(0).toUpperCase() + tHeader.slice(1);
            return upperString.split(/(?=[A-Z])/).join(" ");
        }
    }
    getGSTValue(value, amount){
        return (parseFloat(value)*parseFloat(amount))/100
    }
    getPrice(rate){
        let pieceCount = 0;
        for (const [key, value] of Object.entries(rate)) {
            pieceCount += value;
          }
        return pieceCount;
    }
    getPieces(index){
        let piec = 0;
        let sCount = this.props.printData?.packingSlipViewForInvoice?.data?.[index]?.sizeCount;
        let bCount = this.props.printData?.packingSlipViewForInvoice?.data?.[index]?.boxCount;
        for (const [key, value] of Object.entries(sCount)) {
            piec += value;
          }
        return piec;
        // return piec*bCount;
    }
    getTotalBoxes(type){
        let totalBoxes = 0;
        let totalPieces= 0;
        this.props.printData?.packingSlipViewForInvoice?.data?.map((eachData)=>{
            let pieces = 0;
            for (const [key, value] of Object.entries(eachData.sizeCount)) {
                pieces += value;
              }
            // totalPieces += eachData.boxCount * pieces;
            totalPieces += pieces;
            totalBoxes += eachData.boxCount;
        })
        return type === "pieces" ? totalPieces : totalBoxes;
    }
     getPercentage(num, per){
        return (num/100)*per;
    }
    render(){
        const printData = this.props.printData;
        const refNumber = this.props.refNumber;
        // const tableHeaders = printData.packingSlipViewForInvoice?.headers;
        const tableHeaders = this.props.headers;
        // tableHeaders?.push(tableHeaders.splice(tableHeaders.indexOf("amount"), 1)[0]);
        // tableHeaders?.push(tableHeaders.splice(tableHeaders.indexOf("RR"), 1)[0]);
    return (
        
        <div class="container">
            <div class="row row-main">
                <div class="col-xs-12">
                    <div class="invoice-title text-center">
                        <h2>Tax Invoice</h2>
                    </div>
                    <div class="invoice-title">
                        <h5>Subject to Hyderabad Judiciary</h5>
                    </div>
                    <div class="row final-font">
                        <div class="col-xs-6 info">
                            {/* <h5><b>Company Details</b></h5> */}
                            <br />
                            <img alt="logo" class="img-fluid logo-img" src={logo} />
                            <address class="text-center">
                            <br />
                            {/* <strong>Code            : {printData.companyInfo?.code}</strong><br />
                            <strong>Code Name       : {printData.companyInfo?.name}</strong><br /> */}
                            <strong >{`(${printData.companyInfo?.city} Branch)`}</strong><br /><br />
                            <strong >{printData.companyInfo?.address}</strong><br />
                            <strong>City            : {printData.companyInfo?.city} - {printData.companyInfo?.pincode}</strong><br />
                            <strong>State Code          : {printData.companyInfo?.stateCode} - {printData.companyInfo?.city}</strong>
                            {/* <strong>Contact Name    : {printData.companyInfo?.contactName}</strong><br />
                            <strong>Contact Number  : {printData.companyInfo?.contactNumber}</strong><br />
                            <strong>Mobile Number   : {printData.companyInfo?.mobileNumber}</strong><br /> */} 
                            </address>
                        </div>
                        <div class="col-xs-6 info">
                            <div class="row">
                            <div class="col-xs-6 info">
                                <strong>{`Invoice Number : ${printData.invoiceType}-${printData?.invoiceNumber}`}</strong>
                            </div>
                            <div class="col-xs-6 info">
                                <strong>Date : {this.getInvoiceDate()}</strong>
                            </div>
                            </div>
                            <div class="row">
                            <address class="text-center">
                            <h5 class="billing"><b>Billing Address:</b></h5>
                            {/* <strong>Code            : {printData.transporters?.code}</strong><br /> */}
                            <strong>{printData.partyList?.name}</strong><br />
                            <strong>{printData.partyList?.address}</strong><br />
                            <strong>City: {printData.partyList?.city} - {printData.partyList?.pincode}</strong><br />
                            <strong>Place of Supply : {printData.partyList?.stateCode} - {printData.partyList?.city}</strong><br />
                            {/* <strong>Contact Name    : {printData.transporters?.contactName}</strong><br />
                            <strong>Contact Number  : {printData.transporters?.contactNumber}</strong><br />
                            <strong>Mobile Number   : {printData.transporters?.mobileNumber}</strong><br /> */}
                                {/* <strong>Billed To:</strong><br />
                                    John Smith<br />
                                    1234 Main<br />
                                    Apt. 4B<br />
                                    Springfield, ST 54321 */}
                                </address>
                            </div>
                            <div class="row row-border">
                            <br />
                                <div class="col-xs-6">
                                    <strong>Supplier</strong><br />
                                    <strong> GST No : {printData.companyInfo?.gstIn}</strong><br />
                                    <strong>HSN Code : {printData.totalCalculatedValues?.hsnCode}</strong>
                                    <br />
                                    <br />
                                </div>
                                <div class="col-xs-6">
                                    <strong>Receiver</strong><br />
                                    <strong> GST No : {printData.partyList?.gstIn}</strong><br />
                                    <strong style={{fontSize:"15px"}}>{refNumber}</strong>
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div class="row">
                        <div class="col-md-6">div number one!</div>
                        <div class="col-md-6">div number two!</div>
                    </div> */}
                    {/* <div class="row">
                        <div class="col-xs-6 info">
                            <h5><b>Category Details</b></h5>
                            <address>
                                {printData?.categoryData && this.getCategoryData(printData?.categoryData)}
                                
                            </address>
                        </div>
                        <div class="col-xs-6 info">
                            <h5><b>Party Details</b></h5>
                            <address>
                            {printData?.partyData && this.getCategoryData(printData?.partyData)}
                            </address>
                        </div>
                    </div> */}
                </div>
            </div>
            
            <div class="row final-font">
                <div class="col-md-12">
                    <div class="panel panel-default">
                        {/* <div class="panel-heading">
                            <h3 class="panel-title"><strong>Order summary</strong></h3>
                        </div> */}
                        <div class="panel-body">
                            <div class="">
                                <table class="table info">
                                    <thead>
                                        <tr>
                                            { tableHeaders.length > 0 && tableHeaders?.map((eachHeader) => {
                                                return <td class="text-center info"><strong>{this.getHeaders(eachHeader)}</strong></td>
                                            })
                                            // <td><strong>#</strong></td>
                                            
                                            // <td class="text-center"><strong>Quantity</strong></td>
                                            // <td class="text-right"><strong>Totals</strong></td>
                                            // <td class="text-center"><strong>Price</strong></td>
                                            // <td class="text-center"><strong>Quantity</strong></td>
                                            // <td class="text-right"><strong>Totals</strong></td>
                                            // <td class="text-right"><strong>Totals</strong></td>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {printData?.packingSlipViewForInvoice?.data?.map((eachRow, index) => {
                                            return <tr>
                                                { tableHeaders?.map((eachHeader) => {
                                                    if(eachHeader === "pieces"){
                                                        return <td class="text-center info">{this.getPieces(index)}</td>
                                                        // return <td class="text-center info">{this.getPieces(index)}</td>
                                                    }
                                                    else if(eachHeader === "amount"){
                                                        return <td class="text-center info">{this.getPrice(eachRow["amount"])}</td>
                                                    }
                                                    else if(eachHeader === "reserveNo"){
                                                        return <td class="text-center info">{index + 1}</td>
                                                    }
                                                    else if(eachHeader === "rate"){
                                                        return <td class="text-center info">{this.getPrice(eachRow["sizeRate"])}</td>
                                                    }
                                                    else if(eachHeader === "RR"){
                                                        return <td class="text-center info">{this.getPrice(eachRow["retailPriceArr"])}</td>
                                                    }else {
                                                        return <td class="text-center info">{eachRow[eachHeader]}</td>
                                                    } 
                                            })}
                                            
                                                {/* <td class="text-center">{eachRow.id}</td>
                                                <td class="text-center">{eachRow.BDLNo}</td>
                                                <td class="text-center">{eachRow.BDLCount}</td>
                                                <td class="text-center">{eachRow.TextTileGoods}</td>
                                                <td class="text-center">{eachRow.MRTS}</td>
                                                <td class="text-center">{eachRow.Qty}</td>
                                                <td class="text-center">{eachRow.Rate}</td>
                                                <td class="text-center">{eachRow.Amount}</td> */}
                                            </tr>
                                        })}
                                        {this.props.dummyArr?.map((eachRow) => {
                                            return <tr>
                                                { tableHeaders?.map((eachHeader) => {
                                                return <td class="text-center info"></td>
                                            })}
                                            </tr>
                                        })}
                                        {/* <tr>
                                            <td>BS-400</td>
                                            <td class="text-center">$20.00</td>
                                            <td class="text-center">3</td>
                                            <td class="text-right">$60.00</td>
                                        </tr>
                                        <tr>
                                            <td>BS-1000</td>
                                            <td class="text-center">$600.00</td>
                                            <td class="text-center">1</td>
                                            <td class="text-right">$600.00</td>
                                        </tr> */}
                                        {/* <tr>
                                            <td class="thick-line"></td>
                                            <td class="thick-line"></td>
                                            <td class="thick-line"></td>
                                            <td class="thick-line"></td>
                                            <td class="thick-line"></td>
                                            <td class="thick-line"></td>
                                            <td class="thick-line text-center"><strong>Subtotal</strong></td>
                                            <td class="thick-line text-center"><strong>$600</strong></td>
                                        </tr>
                                        <tr>
                                            <td class="no-line"></td>
                                            <td class="no-line"></td>
                                            <td class="no-line"></td>
                                            <td class="no-line"></td>
                                            <td class="no-line"></td>
                                            <td class="no-line"></td>
                                            <td class="no-line text-center"><strong>Shipping</strong></td>
                                            <td class="no-line text-center"><strong>$15</strong></td>
                                        </tr>
                                        <tr>
                                            <td class="no-line"></td>
                                            <td class="no-line"></td>
                                            <td class="no-line"></td>
                                            <td class="no-line"></td>
                                            <td class="no-line"></td>
                                            <td class="no-line"></td>
                                            <td class="no-line text-center"><strong>Total</strong></td>
                                            <td class="no-line text-center"><strong>$615</strong></td>
                                        </tr> */}
                                    </tbody>
                                </table>
                                <div class="row row-main">
                <div class="col-xs-12">
                    <div class="row">
                        <div class="col-xs-8 info">
                        <div class="row">
                                <div class="col-xs-8 border-right">
                                <div class="row">
                                       <div class="col-xs-3">
                                            Total Boxes :
                                       </div>
                                       <div class="col-xs-3 info">
                                            {this.getTotalBoxes("boxes")}
                                       </div>
                                       <div class="col-xs-3">
                                            Total Pcs :  
                                       </div>
                                       <div class="col-xs-3 info">
                                            {this.getTotalBoxes("pieces")}
                                       </div>
                                       <div class="text-center">
                                           <br />
                                           <br />
                                        <strong>All the goods whose's MRP is not above the  Rs.495/-</strong><br />
                                        <strong>Reserve Charge is Not Applicable</strong><br />
                                        <strong>Goods dispatched By : LOCAL [LC]</strong>
                                        <br />
                                       </div>
                                   </div>
                                </div>
                                <div class="col-xs-4">
                                    <strong>Receiver's signature with stamp</strong>
                                </div>
                            </div>
                            <div class="hor1"></div>
                            <div class="row">
                                <div class="text-center"><h4>Terms and Conditions</h4></div>
                            <strong>1. Goods once sold willnot be taken back or exchange</strong><br />
                            <strong>2. Payment should be made immediately by payee's Account Cheques or Drafts only </strong><br />
                            <strong>3. If the payment will not made with in 10 days from the date of invoice, interest of 18% will be charged per annum</strong><br />
                            <strong>4. We are not responsible for CASH paymnets made to our representatives</strong><br />
                            </div>
                            <br />
                        </div>
                        <div class="col-xs-4 div-height">
                        {/* <div class="hor1"></div> */}
                            <div class="row info height-cls">
                            <div class="col-xs-6"> 
                                <strong>Total : </strong>
                            </div>
                            <div class="col-xs-6">
                                <strong>{printData.totalCalculatedValues?.amount}</strong>
                            </div>
                            </div>
                            {/* <div class="hor1"></div> */}
                            <div class="row info height-cls">
                            <div class="col-xs-6"> 
                                <strong>Discount : </strong>
                            </div>
                            <div class="col-xs-6">
                                <strong>{printData.totalCalculatedValues?.discount}</strong>
                            </div>
                            </div>
                            {/* <div class="hor1"></div> */}
                            <div class="row info height-cls">
                            <div class="col-xs-6"> 
                                <strong>Freight : </strong>
                            </div>
                            <div class="col-xs-6">
                                <strong>{printData.totalCalculatedValues?.freight}</strong>
                            </div>
                            </div>
                            {/* <div class="hor1"></div> */}
                            <div class="row info height-cls">
                            <div class="col-xs-6"> 
                                <strong>Taxable Value : </strong>
                            </div>
                            <div class="col-xs-6 height-cls">
                                <strong>{printData.totalCalculatedValues?.amount - printData.totalCalculatedValues?.discount - 
                                printData.totalCalculatedValues?.freight
                                }</strong>
                            </div>
                            </div>
                            {/* <div class="hor1"></div> */}
                            <div class="row info height-cls">
                            <div class="col-xs-6"> 
                                <strong>{`SGST ${printData.totalCalculatedValues?.sgst}%`} :</strong>
                            </div>
                            <div class="col-xs-6">
                                <strong>{printData.totalCalculatedValues?.sgstval ? this.getPercentage(printData.totalCalculatedValues?.amount - printData.totalCalculatedValues?.discount - 
                                printData.totalCalculatedValues?.freight, printData.totalCalculatedValues?.sgst) : 0}</strong>
                                {/* <strong>{printData.totalCalculatedValues?.igst ? 0 : 
                                this.getGSTValue(printData.totalCalculatedValues?.sgst, 
                                    printData.totalCalculatedValues?.amount - printData.totalCalculatedValues?.discount - 
                                    printData.totalCalculatedValues?.freight)}</strong> */}
                            </div>
                            </div>
                            {/* <div class="hor1"></div> */}
                            <div class="row info height-cls">
                            <div class="col-xs-6"> 
                                <strong>{`CGST ${printData.totalCalculatedValues?.cgst}%`} :</strong>
                            </div>
                            <div class="col-xs-6">
                                <strong>{printData.totalCalculatedValues?.cgstval ? this.getPercentage(printData.totalCalculatedValues?.amount - printData.totalCalculatedValues?.discount - 
                                printData.totalCalculatedValues?.freight, printData.totalCalculatedValues?.cgst) : 0}</strong>
                                {/* <strong>{printData.totalCalculatedValues?.igst ? 0 : this.getGSTValue(printData.totalCalculatedValues?.cgst, 
                                    printData.totalCalculatedValues?.amount - printData.totalCalculatedValues?.discount - 
                                    printData.totalCalculatedValues?.freight)}</strong> */}
                            </div>
                            </div>
                            <div class="row info height-cls">
                            <div class="col-xs-6"> 
                                <strong>{`IGST ${printData.totalCalculatedValues?.igst}%`} :</strong>
                            </div>
                            <div class="col-xs-6">
                                <strong>{printData.totalCalculatedValues?.igstval ? this.getPercentage(printData.totalCalculatedValues?.amount - printData.totalCalculatedValues?.discount - 
                                printData.totalCalculatedValues?.freight, printData.totalCalculatedValues?.igst) : 0}</strong>
                                {/* <strong>{printData.totalCalculatedValues?.igst ? this.getGSTValue(printData.totalCalculatedValues?.igst, 
                                    printData.totalCalculatedValues?.amount - printData.totalCalculatedValues?.discount - 
                                    printData.totalCalculatedValues?.freight) : 0}</strong> */}
                            </div>
                            </div>
                            {/* <div class="hor1"></div> */}
                            {/* <div class="row info">
                            <div class="col-xs-6"> 
                                <strong>Total : </strong>
                            </div>
                            <div class="col-xs-6">
                                <strong>{900}</strong>
                            </div>
                            </div> */}
                            {/* <div class="hor1"></div> */}
                            <div class="row info height-cls">
                            <div class="col-xs-6"> 
                                <strong>Grand Total : </strong>
                            </div>
                            <div class="col-xs-6">
                                <strong>{printData.totalCalculatedValues?.grandTotal}</strong>
                                {/* <strong>{printData.totalCalculatedValues?.amount - printData.totalCalculatedValues?.discount - 
                                printData.totalCalculatedValues?.freight + printData.totalCalculatedValues?.taxAmount
                                }</strong> */}
                            </div>
                            </div>
                            {/* <div class="hor1"></div> */}
                            <div class="row info height-cls">
                            <div class="col-xs-12"> 
                                for <strong>{`Kumar India`}</strong>
                                <br />
                            </div>
                            </div>
                            {/* <div> </div> */}
                            {/* <br/> */}
                            
                        </div>
                    </div>
                </div>
            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{fontSize: "15px", textAlign:"center"}}><strong> # Head Office : 317, Sewree Regd Industrial Estate, Mumbai - 400015 (MS)</strong></div>
        </div>
    );
}
}
