import "./App.css";
import React from "react";
import { BrowserRouter, Routes , Route } from "react-router-dom";
import DateAdapter from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./utils/theme";
import Admin from "./admin/Admin";
import SupplierHome from "./admin/SupplierHome";
import TransporterHome from "./admin/TransporterHome";
import GodownHome from "./admin/GodownHome";
import RetailerHome from "./admin/RetailerHome";
import StoreHome from "./admin/StoreHome";
import CompanyHome from "./admin/CompanyHome";
import UserHome from "./admin/UserHome"
import Category from './admin/Category';
import PaymentForm from "./components/PaymentForm";
import PaymentIntegration from "./components/PaymentIntegration";

import Edit from "./admin/SupplierEdit";
import Tedit from "./admin/Tedit";
import Gedit from "./admin/Gedit";
import Redit from "./admin/Redit";
import Sedit from "./admin/Sedit";
import Cedit from "./admin/Cedit";
import Uedit from "./admin/Uedit";
import CategoryEdit from './admin/CategoryEdit';

// ___________________________
import {
  Home,
  Login,
  NotFound,
  ReceivedInventory,
  InventoryManagement
} from "./pages";
import {
  Layout,
  PrivateRoute
} from "./components";
import SupplierDesign from "./admin/SupplierDesign";
import PartyRates from "./admin/PartyRates";
import SizeRate from "./admin/SizeRate"

function App() {
  // const history = useNavigate();
  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/" element={<PrivateRoute><Layout /></PrivateRoute>} >
              <Route
                index
                path="/"
                element={<Home />}
              />
              <Route
                exact
                path="/received-inventory"
                element={<ReceivedInventory />}
              />
              <Route
                exact
                path="/admin"
                element={<Admin />}
              />
              <Route
                exact
                path="/payment"
                element={<PaymentForm />}
              />
              <Route
                exact
                path="/paymentintegration"
                element={<PaymentIntegration />}
              />
              <Route
                exact
                path="/inventory-management"
                element={<InventoryManagement />}
              />
              <Route exact path='/supplier' element={<SupplierHome />} />
              <Route exact path='/edit/:id' element={<Edit />} />
              <Route exact path='/transporter' element={<TransporterHome />} />
              <Route exact path='/tedit/:id' element={<Tedit />} />
              <Route exact path='/godown' element={<GodownHome />} />
              <Route exact path='/gedit/:id' element={<Gedit />} />
              <Route exact path='/retailer' element={<RetailerHome />} />
              <Route exact path='/redit/:id' element={<Redit />} />
              <Route exact path='/store' element={<StoreHome />} />
              <Route exact path='/sedit/:id' element={<Sedit />} />
              <Route exact path='/companyInfo' element={<CompanyHome />} />
              <Route exact path='/cedit/:id' element={<Cedit />} />
              <Route exact path='/users' element={<UserHome />} />
              <Route exact path='/uedit/:id' element={<Uedit />} />
              <Route exact path='/category' element={<Category />} />
              <Route exact path='/categoryedit/:id' element={<CategoryEdit />} />
              <Route exact path='/supplierdesign' element={<SupplierDesign />} />
              <Route exact path='/partyrates' element={<PartyRates />} />
              <Route exact path='/sizerate' element={<SizeRate />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
