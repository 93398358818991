import React, { useState } from "react";
import axios from "axios";
import { 
  TextField, Grid, Box, Paper, Button, OutlinedInput,
  FormControl, InputLabel, InputAdornment, Chip, MenuItem,
  Select, Typography
} from "@mui/material/";
import Autocomplete from "@mui/material/Autocomplete";
import { LoadingButton } from "@mui/lab"

import { Notification } from "../../../components";
import { API_HOST } from "../../../utils/constants"

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function AddLotForm({
  data,
  onCount,
  sizes,
  total,
}) {

  const defaultValues = {
    purchaseInvoiceInfoId: data?.id,
    lotNumber: "",
    bundleCount: 1,
    totalBoxes: "",
    sizeIds: [],
    quantity: 0,
    totalAmount: "",
    assignee: "",
  };

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [openNotification, setOpenNotification] = React.useState(false);
  const [lotData, setLotData] = useState(defaultValues);

  const addLot = async () => {
    try {
      await axios.post(`${API_HOST}/Lot`,{
        ...lotData,
        totalBoxes: parseInt(lotData.totalBoxes),
        quantity: parseInt(lotData.quantity),
        sizeIds: lotData.sizeIds.join(",")
      })
      onCount();
      setOpenNotification(true)
      setError(false);
      setLotData(defaultValues);
    } catch(e) {
      setOpenNotification(true)
      setError(e.message)
      console.error(e.message)
    }
  }

  const onSubmit = async () => {
    setLoading(true);
    try{
      let lotAmount = lotData.totalAmount!==""? lotData.totalAmount: 0
      let isQuantity = lotData.quantity+total.quantity > data.totalQuantity
      let isAmount = lotAmount+total.amount > data.grandTotal
      if(isQuantity || isAmount) {
        setOpenNotification(true)
        setError(
          `Total ${isQuantity? "quantity,": ""}${isAmount? "amount,": ""} of lots should not exceed ${isQuantity? "quantity,": ""}${isAmount? "amount,": ""} of inventory`
        )
      } else if (lotData.sizeIds.length === 0) {
        setError("Sizes are mandatory field")
      }else {
        setError("")
        await addLot()
      }
    } catch(e) {
      setOpenNotification(true)
      setError(e.message)
      console.error(e.message)
    } finally {
      setLoading(false);
    }
  };

  const sizeSelection = (e) => {
    setLotData({
      ...lotData,
      sizeIds: e.map(s =>s.id),
      // sizeIds: sizes.filter(s => e.includes(s.sizeValue)).map(({ id }) => id),
    });
  }

  return (
    <div>
      <Notification
        open={openNotification}
        onClose={() => setOpenNotification(false)}
        error={error}
      />
      <Box
        component={Paper}
        sx={{ bgcolor: "#dce2e5", m: "0 -24px", p: "24px" }}
      >
        <Grid container spacing={2}>
          <Grid item lg={3} md={4} sm={4} xs={6}>
            <TextField
              fullWidth
              label="Lot No"
              value={lotData?.lotNumber}
              onChange={(e) =>
                setLotData({ ...lotData, lotNumber: e.target.value })
              }
              // type="number"
              variant="outlined"
            />
          </Grid>
          {/* <Grid item lg={3} md={4} sm={4} xs={6}>
            <TextField
              fullWidth
              label="No. of Bundles"
              value={lotData?.bundleCount}
              onChange={(e) =>
                setLotData({
                  ...lotData,
                  bundleCount: parseInt(e.target.value),
                })
              }
              type="number"
              variant="outlined"
            />
          </Grid> */}
          <Grid item lg={3} md={4} sm={4} xs={6}>
            <TextField
              fullWidth
              label="Total Boxes"
              value={lotData?.totalBoxes}
              onChange={(e) =>
                setLotData({ ...lotData, totalBoxes: e.target.value })
              }
              // type="number"
              variant="outlined"
            />
          </Grid>
          <Grid item lg={3} md={3} sm={4} xs={6}>
            <TextField
              fullWidth
              label="Quantity"
              value={lotData?.quantity}
              onChange={(e) =>
                setLotData({ ...lotData, quantity: e.target.value})
              }
              // type="number"
              variant="outlined"
            />
          </Grid>
          <Grid item lg={5} md={4} sm={3} xs={6}>
            {sizes && (
            //   <FormControl fullWidth required error={lotData.sizeIds.length === 0 && error}>
            //     <InputLabel>Sizes</InputLabel>
            //     <Select
            //       multiple
            //       value={sizes.filter(s => lotData.sizeIds.includes(s.id)).map(({ sizeValue }) => sizeValue)}
            //       // onChange={(event) => {
            //       //   const { target: { value } } = event;
            //       //   setLotData({
            //       //     ...lotData,
            //       //     sizeIds: sizes.filter(s => value.includes(s.sizeValue)).map(({ id }) => id),
            //       //   });
            //       // }}
            //       onChange={sizeSelection}
            //       input={<OutlinedInput label="Chip" />}
            //       renderValue={(selected) => (
            //         <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            //           {selected.map((value) => (
            //             <Chip key={value} label={value} />
            //           ))}
            //         </Box>
            //       )}
            //       MenuProps={MenuProps}
            //     >
            //       {sizes.map(({ id, sizeValue }) => (
            //         <MenuItem
            //           key={id}
            //           value={sizeValue}
            //         >
            //           {sizeValue}
            //         </MenuItem>
            //       ))}
            //     </Select>
            // </FormControl>
            <Autocomplete
                multiple
                required
                id="tags-standard"
                options={sizes.sort((a,b) => a.sizeValue - b.sizeValue)}
                onChange={(e, value) => sizeSelection(value)}
                // onChange={(event, value) => {
                //   // const reqSize = value.sizeValue;
                //   setLotData({
                //     ...lotData,
                //     sizeIds: sizes.filter(s => value.includes(s.sizeValue)).map(({ id }) => id),
                //   });
                // }}
                getOptionLabel={(option) => option.sizeValue}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Sizes" />
                )}
              />
            )}
          </Grid>
          <Grid item lg={3} md={4} sm={3} xs={6}>
            <TextField
              fullWidth
              label="Amount"
              value={lotData?.totalAmount}
              onChange={(e) =>
                setLotData({
                  ...lotData,
                  totalAmount: parseInt(e.target.value),
                })
              }
              // type="number"
              variant="outlined"
              InputProps={{
                startAdornment: <InputAdornment position="start">&#8377;</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={4} xs={6}>
            <LoadingButton
              fullWidth
              loading={loading}
              variant="contained"
              color="primary"
              sx={{ fontSize: "16px", height: 56 }}
              onClick={onSubmit}
            >
              Add entry
            </LoadingButton>
          </Grid>
          <Grid item lg={2} md={3} sm={4} xs={6}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              sx={{ fontSize: "16px", height: 56 }}
              onClick={() => {
                setLotData(defaultValues)
                setError(null)
              }}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
        <Typography color="error" mt={1} >{error}</Typography>
      </Box>
    </div>
  );
}
