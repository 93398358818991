import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  TextField,
  Grid
} from "@mui/material";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import {
  Close,
} from "@mui/icons-material";
import axios from "axios";
import { DesktopDatePicker } from "@mui/lab";
import Autocomplete from "@mui/material/Autocomplete";
import { API_HOST } from "../../../utils/constants";


// const useStyles = makeStyles({

//   addColor: {
//     backgroundColor: "#0033a0",
//     color: "white"
//   },
  
// })


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = ({ children, loading, onClose, ...rest }) => {

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...rest}>
      {children}
      {onClose && !loading && (
        <Button
          varaint="text"
          color="secondary"
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            textTransform: "capitalize",
          }}
        >
          <Close />
        </Button>
      )}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };




export default function AddDesignPopUp(props) {

  // const classes = useStyles()
  const [items,setItems] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [supplierData, setSupplierData] = useState([]);
  const [metaDataList, setMetaDataList] = useState([]);
  // const [updateMode, setUpdateMode] = useState(false);
  let initialValues = {
    metadataRelationId:null,
    supplierCode:{supplierCode: 'DEFAULT', supplierName: 'Default', stateCode: '36'},
    effectiveDate:new Date().toISOString(),
    designCode:'',
    defaultBoxSize:'',
    sizeId:null,
    wholesalePrice:'',
    retailPrice:''
}
  let [data, setData] = useState(initialValues);

  let [status, setStatus] = useState();

    function textChange(e ,type='') {
        type ? 
        setData({
          ...data,
          [type]: e
      }) : setData({
            ...data,
            [e.target.name]: e.target.name === "wholesalePrice" || e.target.name === "retailPrice" || e.target.name === "defaultBoxSize"
            ? parseInt(e.target.value) : e.target.value
        })
    }
  //   const changeCase=(event)=>{
  //     event.preventDefault();
  //     setData(event.target.value.toUpperCase());
  // }

  const onChangeMultiSelect = (val, name) => {
    setData({
      ...data,
      [name]: val
  })
    // const ids = val.map((v) => v.id);
    // setstate({ ...state, sizeIds: ids.join() });
  };

    useEffect(() => {
        async function getdata() {
            try {
                const item = await axios.get(`${API_HOST}/SupplierDesignRelation`)
                setItems(item.data);
            } catch (error) {
                console.log("Something is Wrong");
            }
        }
        axios.get(`${API_HOST}/Constants`)
        .then((response) => {
          setSizes(response.data.sizeData);
          setSupplierData(response.data.supplierData);
          // setLoading(false);
        })
        .catch((err) => {
          // setError(err.message);
          // setLoading(false);
        });
        axios.get(`${API_HOST}/MetadataRelation`)
        .then((response) => {
          setMetaDataList(response.data);
          // setLoading(false);
        })
        .catch((err) => {
          // setError(err.message);
          // setLoading(false);
        });
        getdata();
    }, [])

    async function onFormSubmit(e) {
        e.preventDefault();
        const isEmpty = Object.values(data).every(x => x === null || x === '');
        if(isEmpty){
            alert("all fields are mandatory");
        }
        else{ 
          data["metadataRelationId"] = data.metadataRelationId.id;
          data["supplierCode"] = data.supplierCode.supplierCode;
          data["sizeId"] = data.sizeId.id;
          data["effectiveDate"] = new Date(data.effectiveDate).toISOString();
        try {
          await axios.post(`${API_HOST}/SupplierDesignRelation`, {
                ...data,
            });
            alert("Data Successfully Posted.");
            setStatus(true);
            props.updateDesignCodesList();
            setData(initialValues);
        } catch (error) {
            console.log("Something is Wrong");
        }
    }
    }


  return (
      <>
        {/* <Notification
          open={openToast}
          onClose={() => setOpenToast(false)}
          error={error}
        /> */}

        <BootstrapDialog
          sx={{ "& .MuiDialog-paper": { md: { maxWidth: 1000, width: "100%" } } }}
          onClose={props.designModelClose}
          aria-labelledby="customized-dialog-title"
          open={props.designPopUp}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            sx={{ fontWeight: 600 }}
            onClose={props.designModelClose}
          >
            Add Supplier Design Data
          </BootstrapDialogTitle>
          <Grid container >
        <Grid item >
          {/* <Box textAlign="center" p={2} className={classes.addColor} mb={2}>
            <Typography variant="h4">Add Supplier Design Data</Typography>
          </Box> */}
          <form noValidate>
            <Grid ml={1} container spacing={2}>
            <Grid item >
            <Autocomplete
                // multiple
                style={{width:"250px"}}
                id="tags-standard"
                name="metadataRelationId"
                options={metaDataList}
                value={data?.metadataRelationId}
                onChange={(e, val) => onChangeMultiSelect(val, "metadataRelationId")}
                getOptionLabel={(option) => option?.metaDataName}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Metadata" />
                )}
              />
                {/* <TextField autoComplete="metadataRelationId" name="metadataRelationId"  required fullWidth id="metaDate" color="error" label="metadataRelationId" onChange={e => textChange(e)} /> */}
              </Grid>
              <Grid item >
              <Autocomplete
                // multiple
                style={{width:"250px"}}
                id="tags-standard"
                name="supplierCode"
                disabled={true}
                options={supplierData}
                value={data?.supplierCode}
                onChange={(e, val) => onChangeMultiSelect(val, "supplierCode")}
                getOptionLabel={(option) => option?.supplierName}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Supplier Code" />
                )}
              />
                {/* <TextField autoComplete="supplierInfo" name="supplierInfo"  required fullWidth id="supplierInfo" color="error" label="Supplier Code" onChange={e => textChange(e)} /> */}
              </Grid>
              <Grid item >
              <DesktopDatePicker
                required
                name="effectiveDate"
                label="Effective Date"
                inputFormat="dd/MM/yyyy"
                onChange={(e) => textChange(e, "effectiveDate")}
                value={data.effectiveDate}
                // onChange={(date) =>
                //   setInventoryData({
                //     ...inventoryData,
                //     [name]: new Date(date).toISOString(),
                //   })
                // }
                renderInput={(params) => <TextField fullWidth {...params} />}
              />  
                {/* <TextField autoComplete="effectiveDate" name="EffectiveDate" onMouseEnter={changeCase}   required fullWidth id="hsn" color="error" label="Hsn" onChange={e => textChange(e)} /> */}
              </Grid>
              <Grid item >
                <TextField autoComplete="designCode" value={data?.designCode} name="designCode"  required fullWidth id="designCode" color="error" label="Design Name" onChange={e => textChange(e)} />
              </Grid>
              <Grid item >
                <TextField type="number" value={data?.defaultBoxSize} autoComplete="defaultBoxSize" name="defaultBoxSize"  required fullWidth id="defaultBoxSize" color="error" label="Default Box Size" onChange={e => textChange(e)} />
              </Grid>
              <Grid item >
              <Autocomplete
                // multiple
                style={{width:"250px"}}
                id="tags-standard"
                name="sizeId"
                options={sizes}
                value={data?.sizeId}
                onChange={(e, val) => onChangeMultiSelect(val, "sizeId")}
                getOptionLabel={(option) => option?.sizeValue}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Sizes" />
                )}
              />
                {/* <TextField autoComplete="size" name="size"  required fullWidth id="size" color="error" label="Size Range" onChange={e => textChange(e)} /> */}
              </Grid> 
              <Grid item >
                <TextField type="number" value={data.wholesalePrice} autoComplete="wholesalePrice" name="wholesalePrice"  required fullWidth id="wholesalePrice" color="error" label="WholeSale Rate" onChange={e => textChange(e)} />
              </Grid> 
              <Grid item >
                <TextField type="number" value={data.retailPrice} autoComplete="retailPrice" name="retailPrice"  required fullWidth id="retailPrice" color="error" label="Retail Rate" onChange={e => textChange(e)} />
              </Grid> 
            </Grid>
             <Box textAlign="end" m={3}>
                <Button variant="text" color="secondary" onClick={props.designModelClose} sx={{ px: 5, mx: 1 }}>
                    Cancel
                </Button>
              <Button type="submit" variant="contained" color="error" onClick={e => onFormSubmit(e)}>{"Save"}</Button>
            </Box>
          </form>
        </Grid>

      </Grid>
          {/* <Box my={2} sx={{ textAlign: "center" }}>
          <Button variant="text" color="secondary" onClick={closeModal} sx={{ px: 5, mx: 1 }}>
              Add New Design
            </Button>
            <Button variant="text" color="secondary" onClick={closeModal} sx={{ px: 5, mx: 1 }}>
              Cancel
            </Button>
            <LoadingButton
              loading={btnLoading}
              onClick={onSubmit}
              variant="contained"
              sx={{ px: 3 }}
            >
              Save
            </LoadingButton>
          </Box> */}
        </BootstrapDialog>
      </>
    );
}
