import React, { useEffect, useState } from "react";
import axios from "axios";

import Autocomplete from "@mui/material/Autocomplete";
import { DataGrid } from "@mui/x-data-grid";

import { TextField, Button, Stack, Box } from "@mui/material/";

// import * as ReactDOM from "react-dom";
// import { PDFExport } from "@progress/kendo-react-pdf";
// import Content from "./content";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { API_HOST } from "../../../utils/constants";
import "../styles/styles.css";
import { Splitscreen } from "@mui/icons-material";

const PackingSlip = () => {
  const [loading, setLoading] = useState(false);

  const [parties, setParties] = useState();
  const [categories, setCategories] = useState();
  const [godowns, setGodowns] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await axios.get(`${API_HOST}/Constants/partyList`).then((res) => {
          // console.log("res ", res);
          setParties(res.data);
        });

        await axios.get(`${API_HOST}/Constants/`).then((res) => {
          // console.log("res ", res);
          setCategories(res.data.categoryData);
          setGodowns(res.data.godownData);
        });
        setLoading(false);
      } catch (err) {
        console.log(err);
        setParties([]);
        setCategories([]);
        setGodowns([]);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const [partyName, setPartyName] = useState();
  const [categoryId, setCategoryId] = useState();
  const [godown, setGodown] = useState();

  const [tableData, setTableData] = useState(-1);

  const [columns, setColumns] = useState([]);

  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);

  var cols = [];
  var row = [];

  const findSum = (obj) => {
    let sum = 0;
    for (let property in obj) {
      sum += isNaN(Number(obj[property])) ? 0 : Number(obj[property]);
    }
    return sum;
  };

  // reset table and other data
  const reset = () => {
    // setPartyName("");
    // setCategoryId("");
    // setGodown();

    // setParties([]);
    // setCategories([]);
    // setGodowns([]);

    setTableData(-1);

    setColumns([]);
    setRows([]);

    setSelectedRows([]);
  };

  // filter function
  const searchHandler = async () => {
    try {
      if (!partyName || !categoryId || !godown)
        alert("Kindly provide party name and category !!!");
      else {
        setTableData(0);

        axios
          .get(
            `${API_HOST}/InvoiceGeneration/Boxes/${categoryId.categoryId}/${partyName.code}/${godown.godownCode}`
          )
          .then((res) => {
            cols = [
              { field: "id", headerName: "ID", flex: 1, hide:true },
              {
                field: "invoiceNumber",
                headerName: "Invoice Number",
                // type: "number",
                flex: 1
              },
              { field: "designCode", headerName: "Design", flex: 1 },
              {
                field: "pieces",
                headerName: "Pieces",
                // type: "number",
                flex: 1
              },
              {
                field: "boxNumber",
                headerName: "Box Number",
                // type: "number",
                flex: 1
              },
            ];

            if (res && res.data.data.length > 0) {
              // for (const iterator in res.data[0].sizeCount) {
              //   cols.push({ field: iterator, headerName: iterator, width: 70 });
              // }
              // console.log("cols ", cols);
              res.data.headers?.map((sizeHeaders) => {
                cols.push({ field: sizeHeaders.trim(), headerName: sizeHeaders.trim(), flex: 1 })
              })
              setColumns(cols);

              for (let i = 0; i < res.data.data.length; i++) {
                let data = res.data.data[i];
                row.push({
                  id: i + 1,
                  dbId: data.id,
                  invoiceNumber:data.invoiceNumber,
                  designCode: data.designCode,
                  pieces: findSum(data.sizeCount),
                  boxNumber: data.boxNumber,
                  ...data.sizeCount,
                });
              }
              setRows(row);
              setTableData(1);
              row = [];
              cols = [];
              // console.log("row ", row);
            } else {
              setRows([]);
              setColumns([]);
              setTableData(1);
            }
          });
      }
    } catch (error) {}
  };

  // generate PDF - packing slip
  const generatePdf = (selectedRows, pName, categoryName, godownName, reqHeaders) => {
    const headersData = selectedRows;
    // var doc = new jsPDF("p", "pt", "a6");

    // doc.text(20, 20, "This is the first title.");

    // doc.addFont("helvetica", "normal");
    // doc.text(20, 60, "This is the second title.");
    // doc.text(20, 100, "This is the thrid title.");

    // doc.save("demo.pdf");

    // delete(object_name[key_name]);
    selectedRows = selectedRows.map((item) => {
      delete item.id;
      return item;
    });

    Object.prototype.keys = function () {
      return Object.keys(this);
    };

    // generate table cols and data
    // var heads = selectedRows.map(function (el) {
    //   return el.keys();
    // });
    var heads=reqHeaders;

    // heads = heads[0];
    // heads = heads.map((eachCol) => {
    //   return eachCol.charAt(0).toUpperCase() + eachCol.slice(1);
    // })
    // var heads = [
    //   "id",
    //   "dbId",
    //   "designCode",
    //   "pieces",
    //   "boxNumber",
    //   "32 ",
    //   "34 ",
    //   "36 ",
    //   "M ",
    // ];
    var rowData = [];
    for (let i = 0; i < selectedRows.length; i++) {
      var temp = [];
      for (let j = 0; j < reqHeaders.length; j++) {
        temp.push(selectedRows[i][heads[j]]); 
      }
      rowData.push(temp);
    }

    // console.log("heads ", heads);
    // console.log("rowData ", rowData);
    // #### auto table exmple
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const doc = new jsPDF({
      orientation: "portrait",
      unit: "in",
      format: [4, 4]
    });

    // const headers = [
    //   [
    //     "id",
    //     "dbId",
    //     "designCode",
    //     "pieces",
    //     "boxNumber",
    //     "32 ",
    //     "34 ",
    //     "36 ",
    //     "M ",
    //   ],
    // ];
    // const people = [
    //   {
    //     SR: "12",
    //     Design: "12",
    //     36: "12",
    //     38: "12",
    //     40: "12",
    //     42: "12",
    //     44: "12",
    //     PCS: "12",
    //     BOX: 4,
    //   },
    //   {
    //     SR: "12",
    //     Design: "12",
    //     36: "12",
    //     38: "12",
    //     40: "12",
    //     42: "12",
    //     44: "12",
    //     PCS: "12",
    //     BOX: 4,
    //   },
    //   {
    //     SR: "12",
    //     Design: "12",
    //     36: "12",
    //     38: "12",
    //     40: "12",
    //     42: "12",
    //     44: "12",
    //     PCS: "12",
    //     BOX: 4,
    //   },
    // ];

    // // const data = people.map((elt) => [elt.name, elt.profession]);
    // const data = [
    //   [1, 65, "DesignCode1", 3150, 2, " 864", " 818", " 808", " 660"],
    //   [1, 65, "DesignCode1", 3150, 2, " 864", " 818", " 808", " 660"],
    //   [1, 65, "DesignCode1", 3150, 2, " 864", " 818", " 808", " 660"],
    //   [1, 65, "DesignCode1", 3150, 2, " 864", " 818", " 808", " 660"],
    //   [1, 65, "DesignCode1", 3150, 2, " 864", " 818", " 808", " 660"],
    //   // ["SR", "Design", "36", "38", "40", "42", "44", "PCS", "BOX"],
    //   // ["SR", "Design", "36", "38", "40", "42", "44", "PCS", "BOX"],
    // ];

    heads = [heads];

    // rowData.map((eachRow, index) => {
      // for(let i=0; i<eachRow.length; i++){
      //   console.log("eeeeeeeeeeeee", eachRow[i], heads);
      //   if(eachRow[i] === "undefined") delete heads[i]
      // }
    
    let content = {
      head: heads,
      body: rowData
      // body: [eachRow],
      // head: headers,
      // body: data,
    };

    var text = "This is a text without real content but with 59 characters.";

    var lineHeight = doc.getLineHeight(text) / doc.internal.scaleFactor;
    // var splittedText = doc.splitTextToSize(text, 50);
    // var lines = splittedText.length; // splitted text is a string array
    // var blockHeight = lines * lineHeight;
    // var blockHeight = 10;

    // if(index) doc.addPage();

    var yPos = 1;
    var xPos = 1;

    doc.setFontSize(10);

    yPos += lineHeight;
    const title = "Packing Slip (Readymade)";
    doc.text(xPos, yPos, title);

    yPos += lineHeight;
    const godown = "Godown : " + headersData[0].GodownName;
    doc.text(xPos, yPos, godown);

    yPos += lineHeight;
    const partyName = "Party Name : " + headersData[0].PartyName;
    doc.text(xPos, yPos, partyName);

    yPos += lineHeight;
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const d = new Date();
    let month = months[d.getMonth()];
    const pSlipNo = `PSLipNo : ${headersData[0].packingslipid}  ${d.getDate()}-${month}-${d.getFullYear()} `;
    doc.text(xPos, yPos, pSlipNo);

    yPos += lineHeight;
    const category = "Category : " + headersData[0].Category;
    doc.text(xPos, yPos, category);

    // console.log("yPos before", yPos); // 102

    yPos += lineHeight;
    content.startY = yPos;
    doc.autoTable(content);

    // console.log("yPos after", yPos); // 120

    yPos += (content.body.length + 2) * lineHeight + 20;
    const equals = "============================= ";
    doc.text(xPos, yPos, equals);

    // total pieces
    // findSum(data.sizeCount)
    let totalPieces = selectedRows.reduce((n, { Pieces }) => parseInt(n) + parseInt(Pieces), 0);
    let totalBoxes = selectedRows.reduce((n, { BoxCount }) => parseInt(n) + parseInt(BoxCount), 0);
    // console.log("totalPieces ", totalPieces);
    yPos += lineHeight;
    const totalPcs = "Total Pcs : " + totalPieces;
    doc.text(xPos, yPos, totalPcs);

    // let totalBoxes = content.body.length;

    yPos += lineHeight;
    const totalBox = "Total Box : " + totalBoxes;
    doc.text(xPos, yPos, totalBox);

    // console.log("yPos after", yPos);
    // doc.text(xPos, yPos, "----- Next Line");

    // yPos += lineHeight;
    // doc.text(xPos, yPos, "----- Next Line 2");
  // })
    //save pdf
    doc.save("packing-slip.pdf");
  
    // console.log("Packing Slip - downloaded !!!");
    reset();

    // #### auto table exmple
  };

  // generate Packing Slip
  const generatePackingSlip = async () => {
    try {
      if (!selectedRows) alert("Kindly select slips !!!");
      else {
        axios
          .post(
            `${API_HOST}/InvoiceGeneration/GeneratePackingSlip`,
            // [71]
            selectedRows.map((item) => {
              return item.dbId;
            })
          )
          .then((res) => {
            if(res.data.renderData.length > 0 ){
              generatePdf(
                res.data.renderData,
                partyName.name,
                categoryId.categoryName,
                godown.godownName,
                res.data.headers
              );
            } else {
              alert("No Data for Packing Slip !!!")
            }
          });
      }
    } catch (error) {}
  };

  return (
    <>
      <h2>Packing Slip </h2>

      <Box mb={2}>
        {/* <Grid container spacing={1} justifyContent="space-between"> */}

        <Stack direction="row" spacing={2}>
          <div className="form-inline">
            <div className="inputDiv">
              {loading ? (
                "Loading"
              ) : (
                <Autocomplete
                  disablePortal
                  id="party"
                  options={parties}
                  sx={{ width: 300 }}
                  onChange={(e, value) => setPartyName(value)} // prints the selected value
                  value={partyName}
                  // className="autoCompleteEmp"
                  // getOptionSelected={(option, value) =>
                  //   option.name === value.name
                  // }
                  getOptionLabel={(option) => {
                    // console.log(option)
                    return typeof option === "string" ? option : `${option.code} - ${option.name}`
                  }
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Party Name" />
                  )}
                />
              )}
            </div>
            <div className="inputDiv">
              {loading ? (
                "Loading"
              ) : (
                <Autocomplete
                  disablePortal
                  id="category"
                  options={categories}
                  sx={{ width: 300 }}
                  onChange={(event, value) => setCategoryId(value)} // prints the selected value
                  value={categoryId}
                  className="autoCompleteEmp"
                  // getOptionSelected={(option, value) =>
                  //   option.categoryName === value.categoryName
                  // }
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.categoryName
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Category" />
                  )}
                />
              )}
            </div>
            <div className="inputDiv">
              {loading ? (
                "Loading"
              ) : (
                <Autocomplete
                  disablePortal
                  id="godown"
                  options={godowns}
                  sx={{ width: 300 }}
                  onChange={(event, value) => setGodown(value)} // prints the selected value
                  value={godown}
                  className="autoCompleteEmp"
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.godownName
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Godown" />
                  )}
                />
              )}
            </div>
          </div>
          <Button variant="contained" onClick={searchHandler}>
            Search
          </Button>
          {/* <Button variant="contained" onClick={reset}>
            Reset
          </Button> */}
        </Stack>
        <Stack direction="row" spacing={2}>
          <div style={{ height: 400, width: "100%" }}>
            {tableData === 1 && (
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={100}
                rowsPerPageOptions={[1]}
                checkboxSelection
                hideFooterPagination
                onSelectionModelChange={(ids) => {
                  const selectedIDs = new Set(ids);
                  const selectedRows = rows.filter((row) =>
                    selectedIDs.has(row.id)
                  );

                  setSelectedRows(selectedRows);
                }}
              />
            )}
            {tableData === 0 && <h2> Loading ... </h2>}

            <br />
            {selectedRows && selectedRows.length > 0 && (
              <Button variant="contained" onClick={generatePackingSlip}>
                Generate Packing Slip
              </Button>
            )}
            {/* <pre style={{ fontSize: 10 }}>
              {JSON.stringify(selectedRows, null, 4)}
            </pre> */}
          </div>
        </Stack>

        {/* </Grid> */}
      </Box>
    </>
  );
};

export default PackingSlip;
