import React, { useState } from 'react'
import axios from 'axios'
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  styled,
  Button,
  TextField,
  Stack,
  Typography,
} from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { Close } from "@mui/icons-material"

import { Notification } from "../../../components"
import { API_HOST } from '../../../utils/constants'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <Button
          varaint="text"
          color="secondary"
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            textTransform: "capitalize",
          }}
        >
          Close
          <Close />
        </Button>
      ) : null}
    </DialogTitle>
  );
};

export default function ReportDamage({ openModal, setOpenModal, reportData }) {

  const [loading, setLoading] = useState(false)
  const [openToast, setOpenToast] = useState(false)
  const [error, setError] = useState(null)
  const [shortage, setShortage] = useState(0)
  const [damage, setDamage] = useState(0)
  
  const onSubmit = async () => {
    try {
      setLoading(true)
      await axios.patch(`${API_HOST}/LotDetails/${reportData.id}/${shortage}/${damage}`)
      setLoading(false)
      setOpenToast(true)
      setError(null)
    } catch (e) {
      setLoading(false)
      setOpenToast(true)
      setError(e.message)
      console.log(e.message)
    }
  }

  return (
    <>
      <Notification
        open={openToast}
        onClose={() => setOpenToast(false)}
        error={error}
      />
      <BootstrapDialog
        sx={{ "& .MuiDialog-paper": { md: { maxWidth: 944, width: "100%" } } }}
        onClose={() => setOpenModal(false)}
        aria-labelledby="customized-dialog-title"
        open={openModal}
      >
        <BootstrapDialogTitle
          sx={{ fontWeight: 600 }}
          onClose={() => setOpenModal(false)}
        >
          Report Shortage/Damage
        </BootstrapDialogTitle>
        {reportData
        ? (
          <DialogContent style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Stack direction="row" justifyContent="space-evenly" spacing={3} mt={3} width="60%">
              <Typography>
                <Typography fontWeight={600}>{"STI No: "}</Typography>
                {reportData.stiNumber}
              </Typography>
              <Typography>
                <Typography fontWeight={600}>{"Total Number of items: "}</Typography>
                {reportData.quantity}
              </Typography>
              <Typography>
                <Typography fontWeight={600}>{"Lot No: "}</Typography>
                {reportData.lotNumber}
              </Typography>
            </Stack>
            <Grid container spacing={2} mt={3} justifyContent="center" width="60%">
              <Grid item lg={6} md={8} sm={12} xs={12}>
                <TextField
                  label="Shortage"
                  type="number"
                  fullWidth
                  variant="outlined"
                  value={shortage}
                  onChange={(e) => setShortage(parseInt(e.target.value))}
                />
              </Grid>
              <Grid item lg={6} md={8} sm={12} xs={12}>
                <TextField
                  label="Damage"
                  type="number"
                  fullWidth
                  variant="outlined"
                  value={damage}
                  onChange={(e) => setDamage(parseInt(e.target.value))}
                />
              </Grid>
            </Grid>
            <Typography mt={3}>
              <b>{"Total Shortage/Damage: "}</b>
              {shortage+damage}
              <p style={{ fontSize: 11, color: 'red', marginTop: 3 }} >
                {(shortage+damage > Math.abs(reportData?.difference)) && 
                `Sum cannot exceed ${Math.abs(reportData?.difference)}`}
              </p>
            </Typography>
          </DialogContent>
        )
        : (
          <Grid container spacing={2} justifyContent="center" width="100%" height="100%">
            No Data
          </Grid>
        )}
        
        <Box mt={3} mb={3} ml="auto" width="65%" >
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setShortage(0)
              setDamage(0)
            }}
            sx={{ px: 5, mx: 1 }}
          >
            Reset
          </Button>
          <LoadingButton
            loading={loading}
            disabled={!reportData || (shortage+damage > Math.abs(reportData?.difference))}
            loadingPosition="start"
            onClick={onSubmit}
            variant="contained"
            sx={{ px: 3 }}
          >
            Generate Slip
          </LoadingButton>
        </Box>
      </BootstrapDialog>
    </>
  )
}
