import React from 'react'
import { Stack, Skeleton } from "@mui/material"

export default function TableLoader({ width="100%", height=30, barCount=3, spacing=3 }) {
  return (
    <Stack spacing={spacing}>
      {Array(barCount)
        .fill(0)
        .map((_, i) => (
          <Skeleton
            key={i}
            animation="wave"
            variant="rectangular"
            width={width}
            height={height}
          />
      ))}
    </Stack>
  )
}
