import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { LoadingButton } from "@mui/lab"
import axios from "axios";

import { TableLoader, Notification } from "../../../components";
import AddBoxPopup from "./AddBoxPopup";
import { API_HOST, lotTableColumnHeaders, formatPrice } from "../../../utils/constants";

const ConfirmDeleteModal = ({open, btnLoading, handleClose, deleteAction}) => {
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Are you sure you want to delete?</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-delete-description">
          Once you confirm, This lot row will be deleted permanently.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleClose}>Cancel</Button>
        <LoadingButton loading={btnLoading} onClick={deleteAction} autoFocus>
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default function LotTable({ data, count, sizes, total, setTotal }) {
  const { id } = data;
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [lots, setLots] = useState([]);
  const [supplierConstants, setSupplierConstants] = useState([])
  const [currentId, setCurrentId] = useState(null);
  const [lotInfo, setLotInfo] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [error, setError] = useState(null);

  const handleDelete = async () => {
    setBtnLoading(true)
    try{
      await axios.delete(`${API_HOST}/Lot?lotId=${currentId}`)
      setOpenToast(true)
      setOpenDeleteModal(false)
    } catch(e) {
      setOpenToast(true)
      setError(e.message)
    }
    setBtnLoading(false)
  }

  const getLots = useCallback(async () => {
    try{
      const { data } = await axios.get(`${API_HOST}/Lot?purchaseInvoiceId=${id}`)
      setLots(data)
      setTotal({
        amount: data.length > 1
        ? data.map(l => parseInt(l.totalAmount)).reduce((prev, curr) => prev+curr)
        : data[0]?.totalAmount,
        quantity: data.length > 1
        ? data.map(l => l.quantity).reduce((prev, curr) => prev+curr)
        : data[0]?.quantity
      })
    } catch(e) {
      setOpenToast(true)
      setError(e.message)
    }
  }, [id, setTotal])

  const updateDesignCodesList = () => {
    setTimeout(async () => {
      const { data } = await axios.get(`${API_HOST}/Constants/SupplierDesignCode/all`) 
      setSupplierConstants(data)
    }, 200);
  }

  const fetchSupplierConstants = useCallback(async (supplierCode) => {
    try {
      const { data } = await axios.get(`${API_HOST}/Constants/SupplierDesignCode/all`) 
      setSupplierConstants(data)
      setLoading(false)
    } catch (e) {
      console.error(e.message)
    }
  }, [])

  useEffect(() => {
    setLoading(true)
    getLots(id, setTotal)
    if(data?.supplierCode) {
      fetchSupplierConstants(data?.supplierCode)
    }
    setLoading(false)
    return () => {
      setLoading(false)
    }
  }, [id, count, openDeleteModal, data?.supplierCode, fetchSupplierConstants, getLots, setTotal]);

  const getSizes = useCallback((v) => {
    if(!sizes) return ""

    const filtered = sizes.filter((ob) => typeof v==="string" && v.split(",").includes(ob.id.toString()));
    const filteredData = filtered.map(({ sizeValue }) => sizeValue);
    filteredData.sort()
    if(filteredData.length > 1) return `${filteredData[0]}-${filteredData[filteredData.length-1]}`;

    return filteredData[0]
  }, [sizes])

  return loading
  ? <TableLoader />
  :(
    <>
      <Notification
        open={openToast}
        onClose={() => setOpenToast(false)}
        error={error}
      />
      <TableContainer component={Paper} sx={{ overflowX: "auto" }}>
        <Table
          size="small"
          aria-label="lot table"
          sx={{ minWidth: 800, mt: 3 }}
        >
          <TableHead sx={{ bgcolor: "#5b6266" }}>
            <TableRow>
              {lotTableColumnHeaders.map(({ title, align, width }) => (
                <TableCell
                  key={title}
                  sx={{
                    color: "white",
                    fontWeight: 600,
                    align: align || "left",
                    width: width
                  }}>
                  {title}
                </TableCell>
              ))}
              <TableCell sx={{ width: "30%" }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lots.map((v) => (
              <TableRow
                key={Math.random()}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{lots[0].lotNumber}</TableCell>
                <TableCell>{v.bundleCount}</TableCell>
                <TableCell>{v.totalBoxes}</TableCell>
                <TableCell>{getSizes(v.sizeIds)}</TableCell>
                <TableCell align="center">{v.quantity}</TableCell>
                <TableCell align="center">{formatPrice(v.totalAmount)}</TableCell>
                <TableCell align="center" sx={{ width: "80%", display: "flex", justifyContent: "space-between" }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    aria-label="add details"
                    onClick={() => {
                      setLotInfo(v)
                      setOpenModal(true)
                    }}
                  >
                    Add Details
                  </Button>
                  <LoadingButton
                    loading={btnLoading}
                    variant="text"
                    aria-label="delete"
                    onClick={() => {
                      setCurrentId(v.id)
                      setOpenDeleteModal(true)
                    }}
                  >
                    Delete
                  </LoadingButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableHead sx={{ bgcolor: "#5b6266" }}>
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell sx={{ color: "white", fontWeight: 600 }}>
                Total: {total.quantity}
              </TableCell>
              <TableCell sx={{ color: "white", fontWeight: 600, textAlign: "center" }}>
                Total: {formatPrice(total.amount)}
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
        </Table>
        {openDeleteModal && (
          <ConfirmDeleteModal
            open={openDeleteModal}
            btnLoading={btnLoading}
            handleClose={() => setOpenDeleteModal(false)}
            deleteAction={handleDelete}
          />
        )}
        {openModal && lotInfo && (
          <AddBoxPopup
            open={openModal}
            closeModal={() => setOpenModal(false)}
            sizeData={sizes}
            supplierConstants={supplierConstants}
            lotInfo={lotInfo}
            updateDesignCodesList={updateDesignCodesList}
          />
        )}
      </TableContainer>
    </>
  );
}
