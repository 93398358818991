import React from 'react';
import { Navigate, useLocation } from 'react-router-dom'

import { STORAGE_KEY } from '../../utils/constants';

const PrivateRoute = ({ children }) => {
  let location = useLocation();

  const authenticated = localStorage.getItem(STORAGE_KEY) && localStorage.getItem(STORAGE_KEY).length>0

  return authenticated? children: <Navigate to="/login" state={{ from: location }} replace />;
}

export default PrivateRoute
