import React from 'react'
import { Snackbar } from "@mui/material"

import Alert from "../Alert"

export default function Notification({
  open,
  onClose,
  error,
  success="Submitted successfully!",
  width="100%"
}) {
  return (
    <Snackbar open={open} autoHideDuration={3000} onClose={onClose}>
      <Alert
        onClose={onClose}
        severity={error ? "error" : "success"}
        sx={{ ...width }}
      >
        {error ? error : success}
      </Alert>
    </Snackbar>
  )
}
