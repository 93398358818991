import React, { useEffect, useState } from "react";
import axios from "axios";

// mui
import {
  TextField,
  FormControl,
  Grid,
  Box,
  Button,
  Snackbar,
  InputAdornment,
  Autocomplete
} from "@mui/material";
import { DesktopDatePicker, LoadingButton } from "@mui/lab";

// utils
import { Alert } from "../../../components";
import { API_HOST, receivedInventoryColumnLabels, formatPrice } from "../../../utils/constants";

const AddInventoryForm = ({
  constants, 
  setOpenLotInfo,
  setCatId,
  setInventoryData: setInventory,
}) => {

  const defaultValues = {
    categoryId: "",
    supplierCode: "",
    recievedBy: "",
    shippedBy: "",
    invoiceNumber: "",
    lrNumber: "",
    totalQuantity: null,
    receivedStatus: "",
    distributedStatus: "",
    frieght: 0,
    stiNo: null,
    taxableAmount: 0,
    totalAmount: 0,
    grandTotal: 0,
    sgst: 0,
    cgst: 0,
    igst: 0,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    invoiceDate: null,
    receivedDate: new Date().toISOString(),
  }

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [inventoryData, setInventoryData] = useState(defaultValues);
  const [updateMode, setUpdateMode] = useState(false);
  const [invPatchId, setInvPatchId] = useState("");

  useEffect(() => {
    setCatId(inventoryData.categoryId)
  }, [inventoryData.categoryId])

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleAmountChange = (value, name) => {
    const {
      totalAmount,
      frieght,
      taxableAmount,
      sgst,
      cgst,
      igst
    } = inventoryData
    if(name === "totalAmount") {
      let t = parseFloat(value)
      let amount = t + frieght
      let total = (t + frieght) + ((t + frieght)*((sgst || cgst)? (sgst+cgst): igst)/100)
      setInventoryData({
        ...inventoryData,
        taxableAmount: amount,
        totalAmount: t,
        grandTotal: parseFloat(total),
      })
    }
    else if(name === "frieght") {
      let f = parseFloat(value)
      let amount = totalAmount + f
      let total = (totalAmount + f) + ((totalAmount + f)*((sgst || cgst)? (sgst+cgst): igst)/100)
      setInventoryData({
        ...inventoryData,
        taxableAmount: amount,
        frieght: f,
        grandTotal: parseFloat(total)
      })
    }
    else if(name === "sgst") {
      let s = parseFloat(value)
      let tax = (s || cgst)? (s+cgst): igst
      let total = taxableAmount + (taxableAmount*(tax/100))
      setInventoryData({
        ...inventoryData,
        sgst: s,
        grandTotal: parseFloat(total)
      })
    }
    else if(name === "cgst") {
      let c = parseFloat(value)
      let tax = (c || sgst)? (c+sgst): igst
      let total = taxableAmount + (taxableAmount*(tax/100))
      setInventoryData({
        ...inventoryData,
        cgst: c,
        grandTotal: parseFloat(total)
      })
    }
    else if(name === "igst") {
      let i = parseFloat(value)
      let tax = i
      let total = taxableAmount + (taxableAmount*(tax/100))
      setInventoryData({
        ...inventoryData,
        igst: i,
        grandTotal: parseFloat(total)
      })
    }
    else {
      setInventoryData({
        ...inventoryData,
        [name]: parseFloat(value)
      })
    }
  }

  const checkDisability = (name) => {
    if(name === "igst") return !!(inventoryData.sgst || inventoryData.cgst)
    else if(["sgst", "cgst"].includes(name)) return !!inventoryData.igst
    return false;
  }

  const renderFormItem = ({ label, name, type, step, prefix, postfix, required }) => {

    const getNotation = () => {
      if(prefix) return { startAdornment: <InputAdornment position="start">&#8377;</InputAdornment> }
      if(postfix) return { endAdornment: "%" }
      return {}
    }

    if(name === "grandTotal") {
      return (
        <Box textAlign="right" mt={2}>
          Grand Total: {formatPrice(inventoryData.grandTotal)}
        </Box>
      )
    }
    if(step) {
      return (
        <FormControl fullWidth required>
        <TextField
          {...{ label, required }}
          fullWidth
          id={name}
          variant="outlined"
          value={inventoryData[name] || ""}
          onChange={(e) => handleAmountChange(e.target.value, name)}
          disabled={checkDisability(name)}
          InputProps={getNotation()}
        />
        </FormControl>
      )
    }
    if(type === "number") {
      return (
        <TextField
          {...{ label, required }}
          fullWidth
          id={name}
          variant="outlined"
          value={inventoryData[name]}
          onChange={(e) =>
            setInventoryData({
              ...inventoryData,
              [name]: name === "lrNumber"? e.target.value: parseInt(e.target.value),
            })
          }
        />
      )
    }
    if(type === "text") {
      return (
        <TextField
          {...{ label, required }}
          fullWidth
          id={name}
          variant="outlined"
          value={inventoryData[name]}
          onChange={(e) =>
            setInventoryData({
              ...inventoryData,
              [name]: e.target.value,
            })
          }
        />
      )
    }
    if(type === "date") {
      return (
        <DesktopDatePicker
          {...{ label, required }}
          inputFormat="dd/MM/yyyy"
          value={inventoryData[name]}
          onChange={(date) =>
            setInventoryData({
              ...inventoryData,
              [name]: date,
            })
          }
          renderInput={(params) => <TextField fullWidth {...params} />}
        />
      )
    }
    if(type === "select" && constants[`${name}Data`]) {
      let nameValues = constants[`${name}Data`].filter(
        o => o[`${name}${["supplier", "godown"].includes(name)? "Code": "Id"}`] === inventoryData[name === "category"
        ? "categoryId"
        : name === "supplier"
        ? "supplierCode"
        : name === "godown"
        ? "godownCode"
        : name])
      return (
        <FormControl fullWidth>
          <Autocomplete
            disablePortal
            autoComplete
            autoSelect
            id={name}
            {...{ label, required }}
            options={
              constants[`${name}Data`]
                .map(o => ({
                  label: o[`${name}Name`],
                  id: o[`${name}${["supplier", "godown"].includes(name)? "Code": "Id"}`] 
                }))
                .filter(l => l.label!== "")
            }
            onChange={(e, value) => {
              setInventoryData({
                ...inventoryData,
                [name === "category"
                  ? "categoryId"
                  : name === "supplier"
                  ? "supplierCode"
                  : name === "godown"
                  ? "godownCode"
                  : name]: value.id,
              });
            }}
            value={(nameValues.length>0? nameValues[0][`${name}Name`]: "") || null}
            renderInput={(params) => (
              <TextField {...params} label={label} />
            )}
          />
        </FormControl>
      )
    }
    return (
      <TextField
        {...{ label, type, required }}
        fullWidth
        id={name}
        variant="outlined"
        value={inventoryData[name]}
        onChange={(e) =>
          setInventoryData({
            ...inventoryData,
            [name]: e.target.value,
          })
        }
      />
    )
  }

  const purchaseInvoice = async (type) => {
    try {
      inventoryData["invoiceDate"] = new Date(inventoryData.invoiceDate).toISOString();
      inventoryData["receivedDate"] = new Date(inventoryData.receivedDate).toISOString();
      if(updateMode){
        inventoryData["id"] = invPatchId;
        const { data } = await axios.patch( `${API_HOST}/PurchaseInvoice`, inventoryData);
        setInventory(data);
      } else {
        const { data } = await axios.post( `${API_HOST}/PurchaseInvoice`, inventoryData);
        setInventory(data);
        setInvPatchId(data.id);
      }
      setOpenLotInfo(true)
      setError(null)
      setUpdateMode(true)
    } catch(e) {
      console.log("fff", e.message);
      setError(e.message)
    }
  }

  const handleSubmit = async (e) => {
    setLoading(true);
    await purchaseInvoice(e.target.name);
    setOpen(true);
    setLoading(false);
  }


  return (
    <>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={error ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {error? "Please enter correct values": "Submitted successfully!"}
        </Alert>
      </Snackbar>
      <Box component="form" mb={2}>
        <Grid container spacing={2} justifyContent="space-between">
          {receivedInventoryColumnLabels.map((columns, i) => (
            <Grid item lg={3} md={4} sm={6} xs={12} key={i}>
              {renderFormItem({...columns, key: i})}
            </Grid>
          ))}
        </Grid>
        <Box ml="auto" display="table" mt={2}>
          {updateMode && 
          <LoadingButton
          name="update"
          loading={loading}
          onClick={handleSubmit}
          sx={{ ml: 1 }}
          variant="contained"
          color="primary"
        >
          Update
        </LoadingButton>
        }
          <Button
            onClick={() => setInventoryData(defaultValues)}
            variant="contained"
            color="secondary"
            sx={{ ml: 1 }}
          >
            Reset
          </Button>
          <LoadingButton
            loading={loading}
            onClick={handleSubmit}
            sx={{ ml: 1 }}
            variant="contained"
            color="primary"
            disabled={updateMode}
          >
            Save
          </LoadingButton>
        </Box>
      </Box>
    </>
  )
}

export default AddInventoryForm