import React from 'react';
import {
  Button,
} from "@mui/material/";
import { Link } from "react-router-dom";

function PaymentIntegration() {
  return (
    <>
      <div style={{fontSize:"20px"}}>Payment Integration page to be done</div>
      <br />
      <Link to={`/payment`}>
        <Button
          variant="contained"
          color="primary" >
          Back
        </Button>
      </Link>
    </>
  )
}

export default PaymentIntegration