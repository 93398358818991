import React, { useState } from "react";
import axios from "axios";

// react router dom
import { Link, useNavigate } from "react-router-dom";

// material
import { TextField, Button, Box, Snackbar } from "@mui/material";
// images
import Image from "../../img/IMG-20211121-WA0005.jpeg";
import BgImage from "../../img/IMG-20211121-WA0004.jpg";
import { SimpleBackdrop, Alert } from "../../components";

import { API_HOST, STORAGE_KEY } from "../../utils/constants"

export default function Login() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({
    loginName: "",
    password: "",
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const onSubmit = () => {
    setLoading(true);
    axios
      .post(
        `${API_HOST}/Login/Authenticate`,
        data
      )
      .then((res) => {
        setError(false);
        setOpen(true);
        setLoading(false);
        localStorage.setItem(STORAGE_KEY, JSON.stringify(res.data));
        navigate("/")
      })
      .catch((err) => {
        setError(true);
        setOpen(true);
        setLoading(false);
      });
  };
  
  return (
    <Box
      sx={{
        backgroundImage: `linear-gradient(to bottom, rgba(99, 110, 114,0.7), rgba(45, 52, 54,0.8)), url(${BgImage})`,
        height: "100vh",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        objectFit: "cover",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={error ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {error ? "Email or password is incorrect!" : "Login successfully!"}
        </Alert>
      </Snackbar>
      {loading && <SimpleBackdrop />}
      <div>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            minWidth: "400px",
            backgroundColor: "#eb4d4b",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "15px",
            p: "15px",
            mx: 1,
          }}
        >
          <img src={Image} alt="Kumar" style={{ width: "150px" }} />
          <TextField
            onChange={(e) => setData({ ...data, loginName: e.target.value })}
            type="text"
            varaint="outlined"
            placeholder="Username"
            fullWidth
            sx={{ backgroundColor: "#ffff", borderRadius: "5px", mt: 1 }}
          />
          <TextField
            onChange={(e) => setData({ ...data, password: e.target.value })}
            type="password"
            varaint="outlined"
            placeholder="Password"
            fullWidth
            sx={{ backgroundColor: "#ffff", borderRadius: "5px", my: 1 }}
          />
          <Link to="#" style={{ color: "white" }} >Forgot your password?</Link>
        </Box>
        <Box
          sx={{
            maxWidth: "431px",
            mx: 1,
          }}
        >
          <Button
            variant="contained"
            fullWidth
            sx={{
              color: "#ffff",
              backgroundColor: "#0a3d62",
              fontWeight: 600,
              textTransform: "capitalize",
              p: "16px 8px",
              borderRadius: "0 0 15px 15px",
              fontSize: "24px",
              boxShadow: "none",
            }}
            onClick={onSubmit}
          >
            Log In
          </Button>
        </Box>
      </div>
    </Box>
  );
}
