import React,{ useState, useEffect, useCallback } from "react";
import axios from "axios"
import {
  Button, Table, Box, TableBody, TableCell, TableHead,
  TableRow, Dialog, DialogContent, DialogTitle, TextField,
  TableContainer, FormControl, Select, MenuItem, InputAdornment,Autocomplete
} from "@mui/material";
import { LoadingButton } from "@mui/lab"
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

import {
  API_HOST,
  invMgmtBoxPopupLabels,
  invMgmtBoxPopupInfoLabels,
  formatPrice,
  STORAGE_KEY
} from "../../../utils/constants";
import { TableLoader, Notification } from "../../../components"

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <Button
          varaint="text"
          color="secondary"
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            textTransform: "capitalize",
          }}
        >
          Close
          <CloseIcon />
        </Button>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
export default function InventoryBoxPopup({ row, open, supplierCode, onClose, catId, reserved }) {
  const [loading, setLoading] = useState(false)
  const [btnLoading, setBtnLoading] = useState(true)
  const [edited, setEdited] = useState(false)
  const [notification, setNotification] = useState({
    open: false,
    error: null
  })
  const [sizes, setSizes] = useState([]);
  const [parties, setParties] = useState([]);
  const [supplierConstants, setSupplierConstants] = useState([]);
  const [defaultBoxValue, setDefaultBoxValue] = useState([]);
  const [boxes, setBoxes] = useState(Array(row?.boxes || 0).fill(0).map((b,i) =>({
    id: i+1,
    lotDetailsId: row.lotDetailId,
    boxNumber: i+1,
    damaged: 0,
    assignParty: "",
    rate: 0
  })))
  const sizesArray = row.sizeIds.split(",");
  const handleChange = async (index, key, value) => {
    if(key === "assignParty") {
      const [
        { data: price }
      ] = await Promise.all([
        axios.get(`${API_HOST}/PartyDesignRelation/PartyRate/${catId}/${value}/${row.designCode}/${sizesArray[0]}/${JSON.parse(localStorage.getItem(STORAGE_KEY)).loginName}`)
      ]);
      setBoxes(box => box.map((b,i) => i===index? ({
        ...b,
        [key]: value,
        ["rate"]: price
      }): b))
    }
    setBoxes(box => box.map((b,i) => i===index? ({
      ...b,
      [key]: value
    }): b))
  }

  const getAllData = useCallback(async () => {
    let sizesBoxArray = [];
    const rowSizeArray = row.sizeIds.split(",");
    const apiSizeIds = [...new Set(rowSizeArray)];
    setLoading(true)
    try{
      const [
        { data: constants },
        { data: supplierConsts },
        { data: partiesData },
        { data: boxesData },
        { data: defaultBoxRes}
      ] = await Promise.all([
        axios.get(`${API_HOST}/Constants`),
        axios.get(`${API_HOST}/Constants/SupplierDesignCode/all`),
        axios.get(`${API_HOST}/Constants/partyList`),
        axios.get(`${API_HOST}/LotDetailsInfo`, { params: { lotDetailsId: row.lotDetailId } }),
        axios.get(`${API_HOST}/SupplierDesignRelation/${row.designCode}/${apiSizeIds.toString()}`)
      ]);
      
      let sizes = (row.sizeIds || "")
      .split(",")
      .map(s => constants?.sizeData.filter(({ id }) => id===parseInt(s))[0]?.sizeValue)
      setSizes([...new Set(sizes)])
      if(boxesData.length > 0) {
        boxesData.sort((a,b) => a.boxNumber > b.boxNumber? 1: -1)
        setBoxes(boxesData.map(b => {
          b.sizeAndCount.map(s => b[`Size${s.sizeValue}`] = s.count)
          delete b.sizeAndCount
          return b
        }))
        setEdited(true)
      } else {
        let t = {}
        var sizeVal = constants?.sizeData?.filter(function(item) {
          return rowSizeArray.indexOf(item.id.toString()) !== -1;
        });
        const newObj = defaultBoxRes?.map((r, i) => {
          var obj = {};
          if(sizeVal[i]){
            obj[sizeVal[i]["sizeValue"]] = r.defaultBoxSize;
            return obj;
        }
        })
        setDefaultBoxValue(newObj.filter(o => o));
        // setBoxes(boxes => boxes.map(b => ({
        //   ...b,
        //   ...t
        // })))
      }
      setSupplierConstants(supplierConsts);
      setParties(partiesData);
    } catch(err) {
      console.log(err.message);
    }
    setLoading(false)
    setBtnLoading(false)
  }, [row.sizeIds, supplierCode, row?.lotDetailId])
  useEffect(()=>{
    if(defaultBoxValue.length > 0){
      const newBoxes = sizes.length > 1 ? boxes.slice(0, -boxes.length/2) : boxes;
      setBoxes(newBoxes?.map(b => {
        defaultBoxValue?.map(s =>{
          b[`Size${Object.keys(s)[0]}`] = Object.values(s)[0]})
        return b
      }))
    }
  }, [defaultBoxValue]);

  const getRate = useCallback((v) => {
    let sizePriceMap = {}
    supplierConstants
      .filter(s => s.designCode === row?.designCode)
      .forEach(s => sizePriceMap[s.sizeValue] = s.wholesalePrice)
    // { 32: 200 }
    let rates = sizes.filter(s => v[`Size${s}`]>0).map(s => sizePriceMap[s])
    // sizePriceMap[32] * v[Size32]
    return rates.length? Math.max(...rates): 0
  }, [sizes, supplierConstants, row?.designCode])

  const getQuantity = useCallback((v) => {
    let sizeValues = sizes.map(s => v[`Size${s}`])
    let d = !isNaN(v.damaged)? v.damaged: 0
    if(sizeValues.length === 0) {
      return !isNaN(d)? d: 0
    }
    if(sizeValues.length === 1) {
      let res = sizeValues[0]-d
      return !isNaN(res)? res: 0
    }
    else {
      let res = sizeValues.reduce((p, v) => p+v)-d
      return !isNaN(res)? res: 0
    }
  }, [sizes])

  const getTotalQuantity = useCallback(() => {
    let sum = 0, damaged = 0
    boxes.forEach(b => {
      sizes.forEach(s => sum += b[`Size${s}`])
    })
    boxes.forEach(b => {
      damaged += b.damaged
    })
    return sum-damaged
  }, [boxes, sizes])

  const onSubmit = async () => {
    setBtnLoading(true)
    try {
      let sizeIdMap = {}
      supplierConstants.forEach(s => sizeIdMap[s.sizeValue] = s.sizeId)
      await axios.post(
        `${API_HOST}/LotDetailsInfo`,
        boxes.map(b => ({
          ...b,
          sizeAndCount: sizes.map(s => ({
            sizeId: sizeIdMap[s],
            count: b[`Size${s}`],
            sizeValue: s
          }))
        }))
      )
      setNotification({ open: true })
    } catch (e) {
      setNotification({ open: true, error: e.message })
      console.error(e.message)      
    }
    setBtnLoading(false)
  }

  useEffect(() => {
    getAllData()
  }, [getAllData]);

  let textFieldProps = {
    fullWidth: true,
    autoFocus: true,
    size: "small",
    type: "number",
    variant: "outlined",
  }

  return (
    <>
      <Notification
        open={notification.open}
        onClose={() => setNotification({ open: false, error: null })}
        error={notification.error}
      />
      <BootstrapDialog
        sx={{ "& .MuiDialog-paper": { md: { maxWidth: 1000 } } }}
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          sx={{ fontWeight: 600 }}
          onClose={onClose}
        >
          Detailed Inventory Check/Update
        </BootstrapDialogTitle>
        <DialogContent>
          <TableContainer>
            <Table sx={{ minWidth: 800 }}>
              <TableHead sx={{ borderBottom: "none" }}>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {invMgmtBoxPopupInfoLabels.map((title, i) => (
                    <TableCell sx={{ fontWeight: 600 }}>{title}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  key={Math.random()}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row?.boxes}
                  </TableCell>
                  <TableCell>{row?.designCode}</TableCell>
                  <TableCell>{row?.ratePerPiece}</TableCell>
                  <TableCell>{row?.quantity}</TableCell>
                  <TableCell>{formatPrice(row?.amount)}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer>
          {loading
          ? <TableLoader barCount={5} />
          : (
            <Table sx={{ minWidth: 500 }}>
              <TableHead sx={{ bgcolor: "#5b6266" }}>
                <TableRow>
                  {[
                    "Boxes",
                    ...sizes.map(s => `Size${s}`),
                    ...invMgmtBoxPopupLabels
                  ].map((title, i) => (
                    <TableCell key={i} sx={{ color: "#ffff", fontWeight: 600 }}>{title}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {boxes.map((v, i) => (
                  <TableRow
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell key="Box Number" component="th" scope="row" sx={{ width: "10%" }}>
                      {v.boxNumber}
                    </TableCell>
                    {sizes.map(s => (
                      <TableCell key={s} sx={{ width: "10%" }}>
                        <TextField
                          {...{ textFieldProps }}
                          value={v[`Size${s}`] || ""}
                          onChange={(e) => handleChange(i, `Size${s}`, parseInt(e.target.value))}
                        />
                      </TableCell>
                    ))}
                    <TableCell key="Damaged" align="center" sx={{ width: "10%" }}>
                      <TextField
                        {...{ textFieldProps }}
                        value={v.damaged || ""}
                        onChange={(e) => handleChange(i, "damaged", parseInt(e.target.value))}
                      />
                    </TableCell>
                    <TableCell key="Quantity" align="left" sx={{ width: "10%" }}>
                      <TextField
                        disabled
                        {...{ textFieldProps }}
                        value={getQuantity(v)}
                      />
                    </TableCell>
                    <TableCell key="Rate" align="left" sx={{ width: "15%" }}>
                      <TextField
                        disabled
                        {...{ textFieldProps }}
                        value={v.rate || ""}
                        InputProps={{ startAdornment: <InputAdornment position="start">&#8377;</InputAdornment> }}
                      />
                    </TableCell>
                    <TableCell key="Assign Party" align="left" sx={{ width: "20%" }}>
                    <Autocomplete
                      style={{width:"250px"}}
                      id="tags-standard"
                      name="assignParty"
                      options={parties}
                      value={parties.filter((pl) => pl.code == v.assignParty)[0]}
                      onChange={(e, value) => handleChange(i, "assignParty", value.code)}
                      // value={data?.sizeId}
                      // onChange={(e, val) => onChangeMultiSelect(val, "sizeId")}
                      getOptionLabel={(option) => `${option?.code} - ${option?.name}`}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" label="Assign Party" />
                      )}
                    />
                      {/* <FormControl fullWidth>
                        <Select
                          value={v.assignParty}
                          onChange={(e) => handleChange(i, "assignParty", e.target.value)}
                        >
                          {parties.map(({ code, name }, i) => (
                            <MenuItem
                              key={`${name}-${i}`}
                              value={code}
                            >
                              {`${code} - ${name}`}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl> */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableHead sx={{ bgcolor: "#5b6266" }}>
                <TableRow>
                  <TableCell></TableCell>
                  {sizes.map(_ => (
                    <TableCell></TableCell>
                  ))}
                  <TableCell></TableCell>
                  <TableCell align="center" sx={{ color: "#fff" }}>
                    Total quantity: {getTotalQuantity()}
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
            </Table>
          )}
        </TableContainer>
      </DialogContent>
      <Box my={2} sx={{ textAlign: "center" }}>
        <Button
          variant="text"
          color="secondary"
          onClick={onClose}
          sx={{ px: 5, mx: 1 }}
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="secondary"
          sx={{ px: 5 }}
          onClick={onSubmit}
          loading={btnLoading}
        >
          {edited? "Update":"Save"}
        </LoadingButton>
      </Box>
    </BootstrapDialog>
  </>
  );
}
