export const API_HOST = process.env.REACT_APP_API_HOST

export const STORAGE_KEY = "KumarShirts_UserKey"

export const PAGE_LIMIT = 10;

export const receivedInventoryColumnLabels = [
  {
    label: "Supplier Code",
    name: "supplier",
    type: "select",
    required: true,
  },
  {
    label: "Supplier Invoice No.",
    name: "stiNo",
    type: "text",
    required: true,
  },
  {
    label: "Invoice Date",
    name: "invoiceDate",
    type: "date",
    required: false,
  },
  {
    label: "Received on",
    name: "receivedDate",
    type: "date",
    required: false,
  },
  {
    label: "LR No.",
    name: "lrNumber",
    type: "text",
    required: false,
  },
  {
    label: "Category",
    name: "category",
    type: "select",
    required: true,
  },
  {
    label: "Total Quantity",
    name: "totalQuantity",
    type: "number",
    required: true,
  },
  {
    label: "Shipped by",
    name: "shippedBy",
    type: "select",
    required: false,
  },
  {
    label: "Godown",
    name: "godown",
    type: "select",
    required: true,
  },
  {
    label: "Total",
    name: "totalAmount",
    type: "number",
    step:0.01,
    prefix: true,
    required: false,
  },
  {
    label: "Frieght",
    name: "frieght",
    type: "number",
    step:0.01,
    prefix: true,
    required: false,
  },
  {
    label: "Taxable value",
    name: "taxableAmount",
    type: "number",
    step:0.01,
    prefix: true,
    required: false,
  },
  {
    label: "SGST ",
    name: "sgst",
    type: "number",
    step:0.01,
    postfix: true,
    required: false,
  },
  {
    label: "CGST ",
    name: "cgst",
    type: "number",
    step:0.01,
    postfix: true,
    required: false,
  },
  {
    label: "IGST",
    name: "igst",
    type: "number",
    step:0.01,
    postfix: true,
    required: false,
  },
  {
    label: "Grand total",
    name: "grandTotal",
    type: "number",
    required: false,
  },
];

export const lotTableColumnHeaders = [
  { title: "Lot No", width: "10%" },
  { title: "Bundles#", width: "10%" },
  { title: "Boxes", width: "10%" },
  { title: "Size", width: "10%" },
  { title: "Quantity", align: "center", width: "10%" },
  { title: "Total Amount", align: "right", width: "20%" }
]

export const boxTableColumnHeaders = [
  { title: "Boxes", width: "15%" },
  { title: "Design Name", width: "20%" },
  { title: "Size", width: "15%" },
  { title: "Rate/Piece", width: "15%" },
  { title: "Quantity", align: "center", width: "15%" },
  { title: "Amount(INR)", align: "right", width: "15%" },
  { title: "", width: "15%" }
]

export const invMgmtTableColumnHeaders = [
  "",
  "SNo",
  "Supplier Invoice No.",
  "Supplier Code",
  "Category",
  "LR Number",
  "Total Amount",
  "Received on",
  "Rec Status",
  "Dist. Status",
]

export const invMgmtInsideTableLabels = [
  "Lot No",
  "Boxes",
  "Design Name",
  "Sizes",
  "Rate/Piece",
  "Quantity",
  "Difference",
  "Amount",
  "Received(Yes/No)",
  "",
]

export const invoiceTableHeaders = [
  // {id: "invoiceNumber", name: "Invoice Number"},
  // {id: "designCode", name: "Design"},
  // {id: "pieces", name: "Pieces"},
  // {id: "boxNumber", name: "Box Number"},
  "invoiceNumber",
  "designCode",
  "pieces",
  "boxNumber"
];

export const invoiceInsideTableHeaders = [
  "HSN Code",
  "Godown Code",
  "Godown Name",
];


export const invMgmtBoxPopupInfoLabels = [
  "Boxes",
  "Design Name",
  "Rate/price",
  "Quantity",
  "Total Amount",
  ""
]

export const invMgmtBoxPopupLabels = [
  "Damaged",
  "Quantity",
  "Rate",
  "Assign Party"
]

export const tableData = [
  { designCode: "5344", sizeAndCount: { sizeValue: "36", count: 5 }, boxes: 3 },
  { designCode: "5345", sizeAndCount: { sizeValue: "38", count: 7 }, boxes: 4 },
  { designCode: "5346", sizeAndCount: { sizeValue: "40", count: 2 }, boxes: 3 }
]

export const formatPrice = (price) => {
  return parseInt(price || 0).toLocaleString('en-IN',{
    minimumFractionDigits: 2,
    style: 'currency',
    currency: 'INR'
  })
}