import React, {useState} from "react"
import {
  Grid,
  Card,
  CardContent,
  MenuItem,
  InputLabel,
  Select,
  CardActions,
  Button,
  CardHeader,
  FormControl,
  Typography,
  FormLabel
} from "@mui/material/"
import { makeStyles } from '@mui/styles';
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"
import { TextField } from "formik-material-ui";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useNavigate } from "react-router-dom";

const useStyle = makeStyles((theme) => ({
  padding: {
    // padding: theme.spacing(3),
    marginLeft:"25%",
    marginRight:"25%"
  },
  button: {
    margin: theme.spacing(1),
  },
}))

const options = [
  { label: "Member 1", value: "Computer_programmer" },
  { label: "Member 2", value: "web_developer" },
  { label: "Member 3", value: "user_experience_designer" },
  { label: "Member 4", value: "systems_analyst" },
  { label: "Member 5", value: "quality_assurance_tester" },
]

//Data
const initialValues = {
  name: "",
  mobile: "",
  gst: "",
  company: "",
  email: "",
  address: "",
  occupation:options[0].value
}

//password validation
const lowercaseRegEx = /(?=.*[a-z])/
const uppercaseRegEx = /(?=.*[A-Z])/
const numericRegEx = /(?=.*[0-9])/
const lengthRegEx = /(?=.{6,})/

//validation schema
let validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  // mobile: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  // password: Yup.string()
  //   .matches(
  //     lowercaseRegEx,
  //     "Must contain one lowercase alphabetical character!"
  //   )
  //   .matches(
  //     uppercaseRegEx,
  //     "Must contain one uppercase alphabetical character!"
  //   )
  //   .matches(numericRegEx, "Must contain one numeric character!")
  //   .matches(lengthRegEx, "Must contain 6 characters!")
  //   .required("Required!"),
})

const PaymentForm = () => {
  const classes = useStyle();
  // const [members, setmembers] = useState(true);
  const [value, setValue] = React.useState('members');
  const [price, setPrice] = useState(200);
  const navigate = useNavigate();

  const onChange = (event) => {
    setValue(event.target.value);
    event.target.value === "members" ? setPrice(200) : setPrice(400);
  };
  const onSubmit = (values) => {
    navigate("/paymentintegration");
  }

  return (
    <Grid container justify="center" spacing={1}>
      <Grid item md={12}>
        <Card className={classes.padding}>
          <CardHeader title="Training Fees"></CardHeader>
          <CardContent style={{fontSize:"25px"}}>{`RS. ${price}`} <Typography>{`(Includes GST & Bank charges)`}</Typography> </CardContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}>
            {({ dirty, isValid, values, handleChange, handleBlur }) => {
              return (
                <Form>
                  <CardContent>
                    <Grid item container spacing={1} justify="center">
                    <Grid item xs={12} sm={6} md={12}>
                      <FormControl>
                        {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={value}
                          onChange={onChange}
                        >
                          <FormControlLabel value="members" control={<Radio />} label="Members" />
                          <FormControlLabel value="nonmembers" control={<Radio />} label="Non Members" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                      {value === "members" && 
                        <Grid item xs={12} sm={6} md={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel id="demo-simple-select-outlined-label">Chapter</InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            label="Occupation"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.occupation}
                            name="occupation"> 
                            {/* <MenuItem>None</MenuItem> */}
                            {options.map((item) => (
                              <MenuItem key={item.value} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      }
                      <Grid item xs={12} sm={6} md={6}>
                        <Field
                          label="Name"
                          variant="outlined"
                          fullWidth
                          name="name"
                          value={values.name}
                          component={TextField}
                        />
                      </Grid>
                      {/* <Grid item xs={12} sm={6} md={6}>
                        <Field
                          label="Last Name"
                          variant="outlined"
                          fullWidth
                          name="lastName"
                          value={values.lastName}
                          component={TextField}
                        />
                      </Grid> */}
                      <Grid item xs={12} sm={6} md={6}>
                        <Field
                          label="Email"
                          variant="outlined"
                          fullWidth
                          name="email"
                          value={values.email}
                          component={TextField}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Field
                          label="Mobile Number"
                          variant="outlined"
                          fullWidth
                          name="mobile"
                          value={values.mobile}
                          component={TextField}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Field
                          label="GST Number"
                          variant="outlined"
                          fullWidth
                          name="gst"
                          value={values.gst}
                          component={TextField}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Field
                          label="Company Name (As Per GST)"
                          variant="outlined"
                          fullWidth
                          name="company"
                          value={values.company}
                          component={TextField}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Field
                          label="Address"
                          variant="outlined"
                          fullWidth
                          name="address"
                          value={values.address}
                          // type="password"
                          component={TextField}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions>
                    <Button
                      // disabled={!dirty || !isValid}
                      variant="contained"
                      color="primary"
                      type="Submit"
                      className={classes.button}
                      >
                      Pay Now
                    </Button>
                  </CardActions>
                </Form>
              )
            }}
          </Formik>
        </Card>
      </Grid>
    </Grid>
  )
}

export default PaymentForm
